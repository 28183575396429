import {
  achievementsField,
  avatarField,
  birthdayField, businessStartYearField, childrenField, cityField,
  dateOfEntryField, educationField,
  emailField, expertiseField, facebookField, factsAboutMeField, familyStatusField,
  firstNameField,
  forumGroup, industriesField, instagramField, interestsField,
  lastNameField, numberOfEmployeesField,
  passwordField, passwordFieldRequired, personalWwwField,
  phoneField,
  positionTags,
  roleField, sexField,
  statusField, telegramField, turnoverPerYearField, videoPresentationField,
} from 'features/residents/layouts/general';
import { translate } from 'magner';

export const taxi369Layout = [
  /** Left column */
  {
    type: 'column',
    props: {
      span: 12,
      xs: 24,
      styles: { 'padding-right': '12px' },
    },
    layout: [
      /** Name and surname */
      {
        type: 'row',
        props: { justify: 'space-between', elementsGrow: true },
        fields: [
          lastNameField,
          firstNameField,
        ],
      },

      /** Email, phone */
      {
        type: 'row',
        props: { justify: 'space-between', elementsGrow: true, align: 'middle' },
        fields: [
          emailField,
          phoneField,
        ],
      },

      /** Active resident */
      {
        type: 'row',
        props: { justify: 'space-between', elementsGrow: true },
        fields: [statusField],
      },

      /** Password, role */
      {
        type: 'row',
        props: { justify: 'space-between', elementsGrow: true, align: 'middle' },
        fields: [
          {
            type: 'input',
            name: 'roleCurrent',
            props: {
              disabled: true,
              hidden: true,
            },
          },
          roleField,
          passwordField,
          passwordFieldRequired,
        ],
      },

      {
        type: 'row',
        props: { justify: 'space-between' },
        layout: [
          /** Avatar */
          avatarField,

          /** recommendation_resident, entry */
          {
            type: 'column',
            props: {
              span: 16,
              xs: 24,
            },
            layout: [
              dateOfEntryField,
            ],
          },
        ],
      },

      /** Forum group */
      {
        type: 'row',
        props: { justify: 'space-between', elementsGrow: true },
        fields: [forumGroup],
      },

      /** Club position (role) and atlases amount */
      {
        type: 'row',
        props: { justify: 'space-between', elementsGrow: true },
        fields: [positionTags],
      },

      /** Block about person (city, age, contact information, children etc.) */
      {
        type: 'column',
        title: translate('hubstr.resident.form.about_me'),
        props: { span: 24, isPaper: true, titleType: 'heading' },
        layout: [
          /** City and Birth date */
          {
            type: 'row',
            props: { elementsGrow: true },
            fields: [
              cityField,
              birthdayField,
            ],
          },

          /** Gender identity */
          {
            type: 'row',
            props: { elementsGrow: true },
            fields: [sexField],
          },

          /** Education */
          {
            type: 'row',
            props: { elementsGrow: true },
            fields: [educationField],
          },

          /** Family status and children */
          {
            type: 'row',
            props: { elementsGrow: true },
            fields: [
              familyStatusField,
              childrenField,
            ],
          },

          /** Facts about me, Interests (hobbies) */
          {
            type: 'column',
            props: {},
            fields: [
              factsAboutMeField,
              interestsField,
            ],
          },

          /** Personal site and video presentation */
          {
            type: 'row',
            props: { elementsGrow: true },
            fields: [
              personalWwwField,
              videoPresentationField,
            ],
          },

          /** Telegram and VK */
          {
            type: 'row',
            props: { titleType: 'heading', elementsGrow: true },
            title: translate('hubstr.resident.form.contacts.label'),
            fields: [telegramField],
          },

          /** Facebook and Instagram */
          {
            type: 'row',
            props: { elementsGrow: true },
            fields: [
              facebookField,
              instagramField,
            ],
          },
        ],
      },
    ],
  },

  /** Right column */
  {
    type: 'column',
    props: {
      span: 12,
      xs: 24,
      styles: { 'padding-left': '12px' },
    },
    layout: [
      /** Expertise, Goal (target) for a year, achievements, resources (to provide and receive)  */
      {
        type: 'column',
        title: translate('hubstr.resident.form.useful'),
        props: { titleType: 'heading' },
        fields: [
          expertiseField,
          achievementsField,
        ],
      },

      /** Business information */
      {
        type: 'column',
        title: translate('hubstr.resident.form.about_business'),
        props: { isPaper: true, titleType: 'heading' },
        layout: [
          /** Work experience in years */
          {
            type: 'row',
            props: { elementsGrow: true },
            fields: [businessStartYearField],
          },

          /** Turnover (money flow) per year */
          {
            type: 'row',
            props: { elementsGrow: true },
            fields: [turnoverPerYearField],
          },

          /** Number of employees */
          {
            type: 'row',
            props: { elementsGrow: true },
            fields: [numberOfEmployeesField],
          },

          /** Areas of business */
          {
            type: 'row',
            props: { elementsGrow: true },
            fields: [industriesField],
          },
        ],
      },
    ],
  },
];
