import { actionCard, openModal, tableController } from 'magner';
import { ChapterList } from 'features/settings/chapter/types/hubstr';
import CreateChapter from '../chapter/create_chapter.vue';

const chapterTable = tableController<ChapterList>({
  tableLayout: 'auto',
  scrollbarAlwaysOn: true,
  emptyText: 'Список пуст',
  columns: [{
    prop: 'name',
    label: 'Наименование',
  }, {
    prop: 'weight',
    label: 'Вес',
  }, {
    prop: 'id',
    label: '',
    width: 200,
    view: {
      type: 'actions',
      actions: [
        {
          type: 'action',
          action: actionCard<any>(async (data: any) => {
            try {
              await openModal<any>({
                type: 'custom',
                customClass: 'modal-max-width modal-max-width__500',
                handleBeforeClose: true,
                // eslint-disable-next-line no-undef
                component: () => CreateChapter,
                props: {
                  row: data.data.row,
                  update: true,
                },
              });
              return false;
            } catch (e) {
              return false;
            }
          }),
          props: {
            text: 'Изменить',
            type: 'primary',
            plain: true,
          },
        },
      ],
    },
  }],
});

export default chapterTable;
