import { validationController } from 'magner';

/** Validations are functions that are used for form fields validation.
 * Here, you are configuring the object where key is a validation unique name and value is
 * a checking function.<br>
 *
 * First argument is a data you can use to check field value<br>
 * Second argument is callback. If callback argument is 'new Error("message")', the error will be shown over the field.
 */
const validation = validationController({
  'empty-required': ({ value, form }, callback) => {
    if (typeof value === 'undefined'
      || value === null
      || (typeof value === 'string' && !value?.trim().length)
      || (Array.isArray(value) && !value.length)
    ) {
      callback(new Error('Поле обязательно к заполнению'));
    }
    callback();
  },

  'email-required': ({ rule, value }, callback) => {
    if (value && !value.match(/[^@]+@[^.]+\..+/i)) {
      callback(new Error('Неверный формат E-mail'));
    } else {
      callback();
    }
  },

  url: ({ rule, value }, callback) => {
    if (value && !value.match(/(https?:\/\/)[a-zа-яё0-9._-]+\.[a-zа-яё]+(\/?.*)?/i)) {
      callback(new Error('Неверный формат ссылки'));
    } else {
      callback();
    }
  },

  /** Example validator showing that you can access values from other fields of the form */
  passwordConfirm: ({ value, form }, callback) => {
    if (value && form.password !== value) {
      callback(new Error('Пароли не совпадают!'));
    } else if (!value) {
      callback(new Error('Введите повтор пароля'));
    } else {
      callback();
    }
  },

  /** Phone field validation */
  phone: ({ rule, value, form }, callback) => {
    if (value && !value.startsWith('+')) {
      callback(new Error('Номер должен начинаться с "+"'));
    } else {
      callback();
    }
  },

  'max-length-128': ({ value }, callback) => {
    if (value.trim().length > 128) {
      callback(new Error('Максимальное количество символов 128'));
    } else {
      callback();
    }
  },

  'max-length-150': ({ value }, callback) => {
    if (value.trim().length > 150) {
      callback(new Error('Максимальное количество символов 150'));
    } else {
      callback();
    }
  },

  'max-length-250': ({ value }, callback) => {
    if (value.trim().length > 250) {
      callback(new Error('Максимальное количество символов 250'));
    } else {
      callback();
    }
  },

  birthday: ({ value }, callback) => {
    const date = new Date(value);
    const currentDate = new Date();
    if (currentDate.getTime() < date.getTime()) {
      callback(new Error('Некорректная дата рождения'));
    } else {
      callback();
    }
  },

  'resident-password-empty': ({ value, form }, callback) => {
    if (
      form.authMethod !== form.oldAuthMethod
      && form.authMethod === 'form'
    && value.length === 0
    ) {
      callback(new Error('Заполните обязательное поле'));
    } else {
      callback();
    }
  },

  interview: ({ value, form }, callback) => {
    if (form.interviewLayout.gotErrors) {
      form.interviewLayout = {
        ...form.interviewLayout,
        showErrors: true,
      };
    } else {
      callback();
    }
  },
});

export default validation;
