import { v4 as uuidv4 } from 'uuid';
import { fileUpload } from 'features/common/file-request';
import {ClubInClub, ClubInClubEdit, ClubInClubFull, LinkGroup} from 'features/settings/club-in-club/types/hubstr';
import { request } from '~/utils/request';
import { imageUploadCode } from '~/constants';
import {isArray} from "lodash-es";

export const clubInClubRead = request.table<ClubInClub>(async ({ api }) => {
  const res = await api.get<{ links: ClubInClub[] }>('/api/admin/link/list?groupCode=clubs_in_clubs');
  if (res.error) {
    return {
      data: {
        rows: [],
        pagination: null,
      },
    };
  }

  return {
    data: {
      rows: res.data?.links,
      pagination: null,
    },
  };
});

export const linkGroupSearch = request.custom<{ rows: LinkGroup[] }, string>(async ({ api, data }) => {
  const res = await api.get<{ groups: LinkGroup[] }>('/api/admin/links_group/list');
  if (res.error) {
    return {
      data: { rows: [] },
    };
  }

  return {
    data: { rows: res.data?.groups },
  };
});

export const clubInClubGet = request.card<ClubInClubFull, ClubInClub>(async ({ api, data, parseError }) => {
  const res = await api.get<{ link: ClubInClubFull }>(`/api/admin/link/get?link=${data.id}`);
  if (res.error) {
    return { error: parseError(res.error) };
  }
  return {
    data: {
      ...res.data?.link,
      group: res.data?.link.group?.id as any,
    },
  };
});

export const clubInClubCreate = request.card<ClubInClubFull, ClubInClubFull>(async ({ api, parseError, data }) => {
  // filterRequestStrings(data.data);

  const id = uuidv4();

  const body = {
    ...data.data,
    id,
    type: 'externalLink',
    groupCode: 'clubs_in_clubs',
  };

  if (data.data.image) {
    const fileUploadRes = await fileUpload(
      id as string,
      (data.data.image as File[])[0],
      'link',
      imageUploadCode,
      'default',
      true,
    );

    if (fileUploadRes.error) {
      return {
        error: {
          message: '',
          fields: { image: fileUploadRes.error as unknown as string },
        },
      };
    }
    body.image = fileUploadRes.data?.id;
  } else {
    body.image = null;
  }

  const res = await api.post<{ link: ClubInClubFull }>('/api/admin/link/create', body);
  if (res.error) {
    return { error: parseError?.(res.error) };
  }
  return { data: res.data?.link };
});

export const clubInClubUpdate = request.card<ClubInClubEdit, ClubInClubFull>(async ({ api, parseError, data }) => {
  // filterRequestStrings(data.data);

  const body = { ...data, link: data.id };
  if (data.image.id && data.image.newImage && data.image.newImage instanceof File) {

    const fileUploadRes = await fileUpload(
      data.image.id as string,
      data.image.newImage,
      'link',
      imageUploadCode,
      'default',
      true,
    );

    if (fileUploadRes.error) {
      return {
        error: {
          message: '',
          fields: { image: fileUploadRes.error as unknown as string },
        },
      };
    }
    body.image = fileUploadRes.data?.id;
  }
  if (typeof body.image === 'object') {
    body.image = body.image.id
  }
  const res = await api.patch<{ link: ClubInClubEdit }>('/api/admin/link/update', body);
  if (res.error) {
    return { error: parseError?.(res.error) };
  }
  return { data: res.data?.link };
});

export const clubInClubDelete = request.card(async ({ api, data, parseError }) => {
  const res = await api.delete(`/api/admin/link/delete?link=${data.id}`);
  if (res.error) {
    return { error: parseError(res.error) };
  }
  return { data: 'ok' };
});
