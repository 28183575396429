import { translate, filtersFormController } from 'magner';
import type { EventGuest } from 'features/events/guests/types/hubstr';

const eventGuestsFilters = filtersFormController<EventGuest>({
  actions: [],

  submitEvent: 'input',

  pagination: {
    items: 25,
    page: 1,
  },
  filtersInSeparatePanel: true,
  filtersData: {
    firstName: '',
    status: null,
    isOnlineParticipation: null,
  },
  sort: {},

  layout: [
    {
      type: 'input',
      name: 'firstName',
      props: {
        placeholder: translate('hubstr.event.guests.filters.name'),
        inputDelay: 250,
      },
    },
    {
      type: 'select',
      name: 'status',
      options: [
        {
          value: 'willGo',
          label: translate('hubstr.event.guests.statuses.willGo'),
        },
        {
          value: 'willNotGo',
          label: translate('hubstr.event.guests.statuses.willNotGo'),
        },
        {
          value: 'read',
          label: translate('hubstr.event.guests.statuses.read'),
        },
        {
          value: 'new',
          label: translate('hubstr.event.guests.statuses.new'),
        },
        {
          value: 'noAnswer',
          label: translate('hubstr.event.guests.statuses.noAnswer'),
        },
        {
          value: 'favorite',
          label: translate('hubstr.event.guests.statuses.favorite'),
        },
      ],
      props: {
        placeholder: translate('hubstr.event.guests.filters.status'),
        clearable: true,
      },
    },
    {
      type: 'select',
      name: 'isOnlineParticipation',
      options: [
        {
          value: 'online',
          label: translate('hubstr.event.guests.online.online'),
        },
        {
          value: 'offline',
          label: translate('hubstr.event.guests.online.offline'),
        },
      ],
      props: {
        placeholder: translate('hubstr.event.guests.filters.online'),
        clearable: true,
      },
    },
  ],
});

export default eventGuestsFilters;
