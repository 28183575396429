import { CustomNotification } from 'features/settings/notification/types/hubstr';
import { v4 as uuidv4 } from 'uuid';
import { ResidentList } from 'features/residents/types/hubstr';
import { Event } from 'features/events/types/hubstr';
import { NOTIFICATION_STATUS } from 'features/settings/notification/constants';
import { translate, useTranslate } from 'magner';
import { request } from '~/utils/request';

interface Pager {
  currentPage: number,
  totalPages: number,
  totalItems: number,
}

const BASE_URL = '/api/admin/notification/';

export const notificationRead = request.table<CustomNotification[]>(async ({ api, data }) => {
  const pagination = `count=${data.pagination.items || 25}&page=${data.pagination?.page || 1}`;
  let query = '';
  if (Object.keys(data.sort).length) {
    Object.keys(data.sort).map((s, index) => {
      if (data.sort[s]) {
        query += `&sort[${index}][id]=${s}&sort[${index}][value]=${data.sort[s]}`;
      }

      return s;
    });
  }

  const res = await api.get<{ list: CustomNotification[], pager: Pager }>(`${BASE_URL}list?${pagination}${query}`);
  if (res.error) {
    return {
      data: {
        rows: [],
        pagination: null,
      },
    };
  }

  return {
    data: {
      rows: res.data.list,
      pagination: {
        currentPage: res.data.pager?.currentPage || 1,
        totalPages: res.data.pager?.totalPages || 1,
        totalItems: res.data.pager?.totalItems || 0,
      },
    },
  };
});
/**
 * Создать рассылку
 */
export const notificationCreate = request.card<Partial<CustomNotification>>(async ({
  api, parseError, data, router,
}) => {
  const id = uuidv4();

  const body: Partial<CustomNotification> = {
    id,
    caption: data.data.caption,
    text: data.data.text,
    userListType: data.data.userListType,
    notifyAt: data.data.notifyAt,
    status: data.data.draft.length ? 'draft' : 'planned',
  };

  if (data.data.users && data.data.users.length) {
    body.users = data.data.users;
  }

  if (data.data.groupId && data.data.groupId.length) {
    body.groupId = data.data.groupId;
  }

  if (data.data.deeplinkTemplate) {
    body.deeplinkTemplate = data.data.deeplinkTemplate;

    if (!Array.isArray(data.data.deeplinkEntityId)) {
      body.deeplinkEntityId = data.data.deeplinkEntityId;
    }
  }

  const res = await api.post(`${BASE_URL}create`, body);
  if (res.error) {
    return { error: parseError?.(res.error) };
  }

  await router.push({ name: 'notification' });
  return { data: res.data?.user };
});
/**
 * Получить значения по умолчанию для новой рассылки
 */
export const notificationNewGet = request.card<Partial<CustomNotification>>(async ({ api, data, parseError }) => ({
  data: {
    userListType: 'all',
  },
}));
/**
 * Получить созданную рассылку
 */
export const notificationGet = request.card<CustomNotification>(async ({ api, data, parseError }) => {
  const { customT } = useTranslate();
  const res = await api.get<{data: CustomNotification }>(`${BASE_URL}show/${data.id}`);
  if (res.error) {
    return { error: parseError(res.error) };
  }

  return {
    data: {
      ...res.data.data,
      id: data.id,
      users: res.data.data.users.map((u) => u.id),
      draft: res.data.data.status === 'draft' ? [true] : [],
      statusText: customT(translate(NOTIFICATION_STATUS[res.data.data.status].text)),
      notifyAt: new Date(`${res.data.data.notifyAt.date}Z`),
      createAt: new Date(`${res.data.data.createAt.date}Z`),
    },
  };
});
/**
 * Обновить рассылку
 */
export const notificationUpdate = request.card<Partial<CustomNotification>>(async ({
  api, parseError, data, router,
}) => {
  const body: Partial<CustomNotification> = {
    id: data.data.id,
    caption: data.data.caption,
    text: data.data.text,
    userListType: data.data.userListType,
    notifyAt: data.data.notifyAt,
    status: data.data.draft.length ? 'draft' : 'planned',
  };

  if (data.data.users && data.data.users.length) {
    body.users = data.data.users;
  }

  if (data.data.groupId && data.data.groupId.length) {
    body.groupId = data.data.groupId;
  }

  if (data.data.deeplinkTemplate && data.data.deeplinkTemplate.length) {
    body.deeplinkTemplate = data.data.deeplinkTemplate;

    if (!Array.isArray(data.data.deeplinkEntityId)) {
      body.deeplinkEntityId = data.data.deeplinkEntityId;
    }
  }

  const res = await api.patch(`${BASE_URL}update`, body);
  if (res.error) {
    return { error: parseError?.(res.error) };
  }

  await router.push({ name: 'notification' });
  return { data: res.data?.user };
});
/**
 * Удалить рассылку
 */
export const notificationDelete = request.card(async ({
  api, data, parseError, router,
}) => {
  const res = await api.delete(`${BASE_URL}delete?id=${data.id}`);
  if (res.error) {
    return { error: parseError(res.error) };
  }

  await router.push({ name: 'notification' });
  return { data: 'ok' };
});
/**
 * Список шаблонов для диплинков
 */
export const deepLinkList = request.table<any>(async ({ api, data }) => {
  const res = await api.get<any>('/api/admin/link/templates');
  if (res.error) {
    return {
      data: {
        rows: [],
      },
    };
  }

  return {
    data: {
      rows: res.data.list,
    },
  };
});
/**
 * Поиск резидентов по имени
 */
export const searchResidents = request.table<ResidentList>(async ({ api, data }) => {
  let query = '';

  if (data.search) {
    query += `&filters[1][id]=search&filters[1][value]=${data.search}`;
  }

  const res = await api.get<{ list: ResidentList[], pager: Pager }>(
    `/api/admin/user/list?count=200&filters[0][id]=status&filters[0][value]=active${query}`,
  );
  if (res.error) {
    return {
      data: {
        rows: [],
      },
    };
  }

  return {
    data: {
      rows: res.data.list.map((item: ResidentList) => ({
        id: item.id,
        name: `${item.firstName} ${item.lastName}`,
      })),
    },
  };
});
/**
 * Поиск мроприятий
 */
export const searchEvents = request.table<Event>(async ({ api, data }) => {
  const search = data.search ? `&filters[0][id]=search&filters[0][value]=${data.search}` : '';

  const res = await api.get<{ list: Event[], pager: Pager }>(
    `/api/admin/event/list?count=200&page=1${search}`,
  );

  if (res.error) {
    return {
      data: {
        rows: [],
        pagination: null,
      },
    };
  }

  return {
    data: {
      rows: res.data.list,
    },
  };
});
/**
 *
 */
export const searchPosts = request.table<Event>(async ({ api, data }) => {
  const search = data.search ? `&filters[0][id]=search&filters[0][value]=${data.search}` : '';

  const res = await api.get<{ list: Event[], pager: Pager }>(
    `/api/post/list?type=all&count=200&offset=0${search}`,
  );

  if (res.error) {
    return {
      data: {
        rows: [],
        pagination: null,
      },
    };
  }

  return {
    data: {
      rows: res.data.list,
    },
  };
});
/**
 *
 */
export const searchPositionTags = request.table<any>(async ({ api, data }) => {
  const search = data.search ? `&filters[0][id]=search&filters[0][value]=${data.search}` : '';

  const res = await api.get<{ list: Event[], pager: Pager }>(
    `/api/admin/user/position/list?count=200&offset=0${search}`,
  );

  if (res.error) {
    return {
      data: {
        rows: [],
        pagination: null,
      },
    };
  }

  return {
    data: {
      rows: res.data.list,
    },
  };
});
