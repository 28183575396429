import { BansList } from 'features/settings/bans/types/hubstr';
import { request } from '~/utils/request';

const BASE_URL = '/api/admin/ignore';

interface Pager {
  currentPage: number,
  totalPages: number,
  totalItems: number,
}

const mockBanList = {
  code: 200,
  msg: 'ok',
  list: [
    {
      id: 'ea565da4-0f4f-4e9c-aa28-89a58b8b23f8',
      ignoring: {
        id: 'faec1803-143d-46b0-90d1-06496df41a03',
        name: 'Бизнес-клуб «Атланты»',
      },
      ignored: {
        id: '886cc4f9-8d52-4622-b7ec-a67f91ada3bb',
        name: 'Юлий Капустин',
      },
      created_at: '2023-02-08T13:36:54.000Z',
    },
    {
      id: 'ea565da4-0f4f-4e9c-aa28-89a58b8b23f8',
      ignoring: {
        id: 'faec1803-143d-46b0-90d1-06496df41a03',
        name: 'Моковое рандомное название',
      },
      ignored: {
        id: '886cc4f9-8d52-4622-b7ec-a67f91ada3bb',
        name: 'Иннокентий Смактуновский',
      },
      created_at: '2023-01-068T13:36:54.000Z',
    },
  ],
  pager: {
    currentPage: 1,
    totalPages: 1,
    totalItems: 2,
  },
};

export const bansHead = request.table<BansList>(async ({ api, data }) => {
  const pagination = `?count=${data.pagination.items || 25}&page=${data.pagination?.page || 1}`;
  // TODO: вернуть сортировку
  
  // GET BANS LIST
  const res = await api.get<{ list: BansList[], pager: Pager }>(
    `${BASE_URL}/list/${pagination}`,
  );

  if (res.error) {
    return {
      data: {
        rows: mockBanList.list,
        pagination: null,
      },
    };
  }

  const banRemove = async() => {
    // TODO change banId to real from request.
    const banId = 'ea565da4-0f4f-4e9c-aa28-89a58b8b23f8';
    const resData = await api.post<{ event: Event }>('/api/admin/ignore/remove', { ignoreId: banId });
    if (res.error) {
      return res.error;
    }
    return resData.data;
  };

  return {
    data: {
      rows: res.data.list,
      pagination: {
        currentPage: res.data.pager?.currentPage || 1,
        totalPages: res.data.pager?.totalPages || 1,
        totalItems: res.data.pager?.totalItems || 0,
      },
    },
  };
});
