import { ResidentList, Tag } from 'features/residents/types/hubstr';
import { translate } from 'magner';
import CommunityManagerLink from 'features/residents/table/components/communityManagerLink.vue';
import { TableColumn } from 'magner/lib/types/components/table';
import { formatPhone, formatShortDate } from '~/utils/format-date';

export const avatarColumn: TableColumn<ResidentList> = {
  prop: 'avatar',
  label: translate('hubstr.resident.table.avatar'),
  view: {
    // @ts-ignore
    type: 'image',
    // @ts-ignore
    nestedKey: 'thumb_100',
  },
  width: 90,
  fixed: 'left',
};

export const fioColumn: TableColumn<ResidentList> = {
  prop: 'fio',
  label: translate('hubstr.resident.table.fio'),
  className: 'resident-fio-cell',
  width: 150,
  fixed: 'left',
};

export const phoneColumn: TableColumn<ResidentList> = {
  prop: 'phone',
  label: translate('hubstr.resident.table.phone'),
  width: 170,
  view: {
    type: 'text',
    formatter: (_, row) => formatPhone(row.phone),
  },
};

export const emailColumn: TableColumn<ResidentList> = {
  prop: 'email',
  label: translate('hubstr.resident.table.email'),
  width: 250,
};

export const guestColumn: TableColumn<ResidentList> = {
  prop: 'onGuestMonth',
  label: translate('hubstr.resident.table.guest'),
  view: {
    type: 'tags',
    formatter: (_, row) => (row.onGuestMonth ? 'Предоставлен' : []),
  },
  width: 140,
};

export const birthdayColumn: TableColumn<ResidentList> = {
  prop: 'birthday',
  label: translate('hubstr.resident.table.birthday'),
  view: {
    type: 'text',
    formatter: (_, row) => formatShortDate(row.birthday),
  },
  width: 150,
};

export const chapterColumn: TableColumn<ResidentList> = {
  prop: 'chapter',
  label: translate('hubstr.resident.table.chapter'),
  width: 150,
  view: {
    type: 'text',
    formatter: (_, row) => `${row.chapter ? row.chapter.name : ''}`,
  },
};

export const positionTagsColumn:TableColumn<ResidentList> = {
  prop: 'positionTags',
  label: translate('hubstr.resident.table.tags'),
  view: {
    type: 'tags',
    formatter: (cellValue) => (cellValue as ResidentList['positionTags']).map((tag: Tag) => ({
      text: tag.name,
      backgroundColor: tag.color,
      textColor: tag.color ? '#FFFFFF' : '',
    })),
  },
  minWidth: 150,
};

export const communityManagerColumn: TableColumn<ResidentList> = {
  prop: 'communityManager',
  label: translate('hubstr.resident.table.communityManager'),
  view: {
    type: 'custom',
    component: () => CommunityManagerLink,
  },
  width: 150,
};

export const statusColumn: TableColumn<ResidentList> = {
  prop: 'status',
  label: translate('hubstr.resident.table.status'),
  view: {
    type: 'tags',
    formatter: (cellValue) => (cellValue as ResidentList['status']).title,
  },
  width: 100,
};
