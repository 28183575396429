import communityManagersTable from 'features/settings/community-managers/table/hubstr';
import communityManagersFilters from 'features/settings/community-managers/filters/hubstr';
import { tablePageController, translate } from 'magner';
import { Resident } from 'features/residents/types/hubstr';
import { residentRead } from 'features/residents/requests/hubstr';

const communityManagersConfig = tablePageController<Resident>({
  header: {
    title: translate('atlanty.community_manager.table.title'),
  },
  request: residentRead,
  table: communityManagersTable as any,
  filters: communityManagersFilters,
});

export default communityManagersConfig;
