import { tableController, translate } from 'magner';
import {
  avatarColumn,
  birthdayColumn,
  communityManagerColumn,
  guestColumn,
  emailColumn,
  fioColumn,
  phoneColumn,
  statusColumn,
  chapterColumn,
} from 'features/residents/table/general';
import type { ResidentList } from 'features/residents/types/hubstr';

const residentsTable = tableController<ResidentList>({
  tableLayout: 'auto',
  emptyText: translate('hubstr.resident.table.not_found'),
  rowLink: (row) => ({ name: 'resident', params: { id: row.id } }),
  columns: [
    avatarColumn,
    fioColumn,
    phoneColumn,
    chapterColumn,
    communityManagerColumn,
    statusColumn,
    {
      prop: 'positionTags',
      label: translate('hubstr.resident.table.tags'),
      view: {
        type: 'tags',
        formatter: (cellValue) => (cellValue as ResidentList['positionTags']).map((tag) => tag.name),
      },
      minWidth: 200,
    },
    guestColumn,
    emailColumn,
    birthdayColumn,
  ],
});

export default residentsTable;
