import { actionCard, openModal, tablePageController, translate } from 'magner';
import eventGuestsTable from 'features/events/guests/table/hubstr';
import eventGuestsFilters from 'features/events/guests/filters/hubstr';
import { eventGuestsRead } from 'features/events/guests/requests/hubstr';
import { EventGuest } from 'features/events/guests/types/hubstr';
import ExportForm from './export.vue';

const eventGuestsConfig = tablePageController<EventGuest>({
  header: {
    title: translate('hubstr.event.guests.table.title'),
    tabs: [
      {
        label: translate('hubstr.event.tabs.form'),
        active: false,
        link: { name: 'event' },
      },
      {
        label: translate('hubstr.event.tabs.guests'),
        active: true,
        link: { name: 'event-guests' },
      },
    ],
    actions: [
      {
        type: 'action',
        props: {
          type: 'primary',
          text: 'Выгрузить',
        },
        emits: 'exports',
        action: actionCard<any>(async (data) => {
          try {
            await openModal<any>({
              type: 'custom',
              customClass: 'modal-max-width modal-max-width__500',
              handleBeforeClose: true,
              component: () => ExportForm,
              props: {},
            });
            return false;
          } catch (e) {
            return translate('atlanty.notification.form.failed_creation');
          }
        }),
      },
    ],
  },
  request: eventGuestsRead,
  table: eventGuestsTable,
  filters: eventGuestsFilters,
});

export default eventGuestsConfig;
