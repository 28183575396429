import {
  actionCard, openModal, tableController, translate,
} from 'magner';
import { Resident, ResidentList } from 'features/residents/types/hubstr';
import ResidentLink from './residentLink.vue';
import { formatPhone, formatShortDate } from '~/utils/format-date';
import CommunityManagerEditForm from '../layout/hubstr.vue';
import CommunityManagerLink from './communityManagerLink.vue';

const communityManagersTable = tableController<ResidentList>({
  tableLayout: 'auto',
  scrollbarAlwaysOn: true,
  emptyText: translate('atlanty.community_manager.table.not_found'),
  rowSelectable: {
    rowIdKey: 'id',
    actions: [
      {
        type: 'action',
        props: {
          type: 'primary',
          text: translate('atlanty.community_manager.table.change_community_manager'),
        },
        emits: 'update-table',
        action: actionCard<any>(async (data) => {
          try {
            await openModal<any>({
              type: 'custom',
              handleBeforeClose: true,
              customClass: 'change-community-manager-modal',
              component: () => CommunityManagerEditForm,
              props: {
                residentsList: (data.data as unknown as {selected: Resident[]}).selected,
              },
            });
            return false;
          } catch (e) {
            return translate('atlanty.community_manager.form.failed_action');
          }
        }),
      },
    ],
  },
  columns: [
    {
      prop: 'avatar',
      label: translate('atlanty.community_manager.table.avatar'),
      view: {
        // @ts-ignore
        type: 'image',
        // @ts-ignore
        nestedKey: 'thumb_100',
      },
      width: 90,
      fixed: 'left',
    },
    {
      prop: 'fio',
      label: translate('atlanty.community_manager.table.fio'),
      className: 'cm-fio-cell',
      width: 150,
      fixed: 'left',
      view: {
        type: 'custom',
        component: () => ResidentLink,
      },
    },
    {
      prop: 'phone',
      label: translate('atlanty.community_manager.table.phone'),
      width: 170,
      view: {
        type: 'text',
        formatter: (_, row) => formatPhone(row.phone),
      },
    },
    {
      prop: 'email',
      label: translate('atlanty.community_manager.table.email'),
      width: 250,
    },
    {
      prop: 'birthday',
      label: translate('atlanty.community_manager.table.birthday'),
      view: {
        type: 'text',
        formatter: (_, row) => formatShortDate(row.birthday),
      },
      width: 150,
    },
    {
      prop: 'communityManager',
      label: translate('atlanty.community_manager.table.community_manager'),
      view: {
        type: 'custom',
        component: () => CommunityManagerLink,
      },
    },
    {
      prop: 'status',
      label: translate('atlanty.community_manager.table.status'),
      view: {
        type: 'tags',
        formatter: (cellValue) => (cellValue as ResidentList['status']).title,
      },
      width: 100,
    },
  ],
});

export default communityManagersTable;
