<template>
  <el-button
    v-if="row.communityManager && row.communityManager.id"
    @click="clickButton"
  >
    {{ `${row.communityManager.firstName} ${row.communityManager.lastName}` }}
  </el-button>
</template>

<script lang="ts">
import {
  defineComponent, PropType,
} from 'vue';

export default defineComponent({
  name: 'CommunityManagerLink',
  props: {
    row: {
      type: Object as PropType<any>,
      required: true,
    },
    column: {
      type: Object as PropType<any>,
      required: true,
    },
  },
  setup (props) {
    // @ts-ignore
    const clickButton = () => {
      const lstorage = JSON.parse(localStorage.getItem('magner-hubstr') || '');
      lstorage.filters['community-managers'].filters.communityManager = props.row.communityManager.id;
      localStorage.setItem('magner-hubstr', JSON.stringify(lstorage));

      window.location.href = '/community-managers';
    };

    return {
      clickButton,
    };
  },
});
</script>
