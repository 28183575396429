import { ROLE } from '~/constants';
import { request } from '~/utils/request';

type ProxyFunc<BEFORE, AFTER> = (data: BEFORE) => AFTER;

export interface User {
  id: string,
  name: string,
  roles: [
    ROLE.ADMIN,
  ],
}

export interface Response {
  status: boolean,
  code: number,
  msg: string,
  user: User,
  token: string,
}

export interface Proxy {
  role: string | null,
  token: string,
  user: User,
}

export const dataToProxy: ProxyFunc<Response, Proxy> = (data) => ({
  token: data.token,
  role: data.user.roles?.length ? data.user.roles[0] : null,
  user: data.user,
});

const loginRequest = request.profile(async ({
  data, api, parseError, lstorage,
}) => {
  const res = await api.post<Response>('/api/admin/auth/login', {
    login: data.login?.replace(/[^0-9]/g, ''),
    password: data.password,
  });
  if (res.error) {
    const e = parseError(res.error);
    lstorage.delete('token');
    api.instance.defaults({ headers: {} }, false);
    return {
      error: {
        message: Object.keys(e.fields).length ? '' : (e.message || 'Не удалось авторизоваться'),
        fields: e.fields,
      },
    };
  }

  const proxied = dataToProxy(res.data);
  lstorage.put('token', proxied.token);
  request.api.instance.defaults({
    headers: {
      Authorization: `Bearer ${proxied.token}`,
    },
  });

  return {
    data: {
      user: proxied.user,
      role: proxied.role,
    },
  };
});

export default loginRequest;
