import type { EventGuest } from 'features/events/guests/types/hubstr';
import type { EventReceive } from 'features/events/types/hubstr';
import { translate } from 'magner';
import { request } from '~/utils/request';
import { API_URL } from '~/constants';

interface Pager {
  currentPage: number;
  totalPages: number;
  totalItems: number;
}

export const eventGuestsRead = request.table<EventGuest>(
  async ({ api, data }) => {
    // const { customT } = useTranslate();
    const pagination = `?count=${data.pagination.items || 25}&page=${
      data.pagination?.page || 1
    }`;
    const sort = '&sortBy=firstName&sortWay=asc';

    const eventId = window.location.pathname
      .replace('/events/', '')
      .replace('/guests', '');
    const event = `&filters[0][id]=event&filters[0][value]=${eventId}`;

    const search = data.filters.firstName
      ? `&filters[1][id]=search&filters[1][value]=${data.filters.firstName}`
      : '';
    const status = data.filters.status
      ? `&filters[2][id]=status&filters[2][value]=${data.filters.status}`
      : '';
    const isOnlineParticipation = data.filters.isOnlineParticipation
      ? `&filters[3][id]=isOnlineParticipation&filters[3][value]=${data.filters.isOnlineParticipation === 'online' ? 1 : 0}`
      : '';
    const chapter = data.filters.chapter
      ? `&filters[4][id]=chapter&filters[4][value]=${data.filters.status}`
      : '';

    const [resGuests, resEvent] = await Promise.all([
      api.get<{ list: EventGuest[]; pager: Pager }>(
        `/api/admin/event/invite/list${pagination}${sort}${event}${search}${status}${isOnlineParticipation}${chapter}`,
      ),
      api.get<{ event: EventReceive }>(`/api/admin/event?event=${eventId}`),
    ]);
    if (resGuests.error) {
      return {
        data: {
          rows: [],
          pagination: null,
        },
      };
    }

    const headerEl = document.querySelector('.page .magner-page-header_title');
    if (headerEl && resEvent.data) {
      headerEl.textContent = `Участники мероприятия "${resEvent.data.event.name}"`;
    }

    return {
      data: {
        rows: resGuests.data.list,
        pagination: {
          currentPage: resGuests.data.pager?.currentPage || 1,
          totalPages: resGuests.data.pager?.totalPages || 1,
          totalItems: resGuests.data.pager?.totalItems || 0,
        },
      },
    };
  }
);

export const exportFile = request.custom(async ({ data, lstorage }) => {
  const token = lstorage.read('token');

  const eventId = window.location.pathname
    .replace('/events/', '')
    .replace('/guests', '');

  const arrayQS = window.location.search.split('%2522');
  const mappingStatus = ['new', 'read', 'willGo', 'willNotGo'];
  const isOnlineFilter = arrayQS.includes('online');

  const isOnlineParticipation = isOnlineFilter || arrayQS.includes('offline')
    ? `filters[0][id]=isOnlineParticipation&filters[0][value]=${
      isOnlineFilter ? 1 : 0
    }`
    : '';

  const filteredPath = mappingStatus.filter((el) => window.location.search.split('%2522').includes(el));

  const status = filteredPath.length > 0
    ? `&filters[1][id]=status&filters[1][value]=${filteredPath[0]}`
    : '';

  try {
    // @ts-ignore
    fetch(`${API_URL}/api/admin/event/${eventId}/report/${
      data.format ? 'csv' : 'xlsx'
    }/members?${isOnlineParticipation}${status}`, {
      method: 'GET',
      headers: {
        Authorization: `Bearer ${token}`,
        'Content-Type': 'application/json',
      },
    })
      // eslint-disable-next-line consistent-return
      .then((res) => {
        if (res.ok) {
          return res.blob();
        }
      })
      .then((blob) => {
        if (blob === undefined) return;

        const url = URL.createObjectURL(blob);
        const a = document.createElement('a');
        a.href = url;
        // @ts-ignore
        a.download = `Выгрузка участников.${data.format ? 'csv' : 'xlsx'}`;
        document.body.appendChild(a);
        a.click();
        a.remove();
      });
  } catch (error) {
    console.log(error);
  }

  return { data: 'ok' };
});
