<template>
  <el-dialog v-model="isModalVisible" title="Редактирование клуба" width="600px" v-loading.fullscreen.lock="loading">
    <el-form :model="clubData" label-width="120px">

      <!-- Название -->
      <el-form-item label="Название" :required="true">
        <el-input v-model="clubData.title" placeholder="Введите название клуба"></el-input>
      </el-form-item>

      <!-- Теги -->
      <el-form-item label="Теги" :required="true">
        <el-select v-model="clubData.tags" multiple placeholder="Выберите теги">
          <el-option
            v-for="tag in allTags"
            :key="tag.id"
            :label="tag.name"
            :value="tag.id">
          </el-option>
        </el-select>
      </el-form-item>

      <!-- Ссылка -->
      <el-form-item label="Ссылка" :required="true">
        <el-input v-model="clubData.url" placeholder="Введите ссылку"></el-input>
      </el-form-item>

      <!-- Описание -->
      <el-form-item label="Описание">
        <el-input
          type="textarea"
          v-model="clubData.description"
          placeholder="Введите описание"
          :rows="4">
        </el-input>
      </el-form-item>

      <!-- Изображение -->
      <el-form-item label="Изображение">
        <el-upload
          class="upload-demo"
          drag
          action="#"
          :before-upload="beforeUpload"
          :on-change="handleChange"
          :show-file-list="false"
          :accept="imageAccept" >
          <i class="el-icon-upload"></i>
          <div class="el-upload__text">Перетащите изображение сюда или нажмите для выбора</div>
          <img
            v-if="imagePreview"
            :src="imagePreview"
            alt="Изображение клуба"
            style="max-width: 100%;
             border-radius: 8px;"/>
        </el-upload>
      </el-form-item>


    </el-form>

    <!-- Кнопки сохранения и удаления -->
    <template #footer>
      <el-button @click="closeModal">Удалить</el-button>
      <el-button type="primary" @click="saveClubData">Сохранить</el-button>
    </template>
  </el-dialog>

<!--  &lt;!&ndash; Confirm Dialog &ndash;&gt;-->
<!--  <el-dialog-->
<!--    :visible.sync="confirmDialogVisible"-->
<!--    title="Внимание!">-->
<!--    <span>Вы уверены, что хотите закрыть окно?</span>-->
<!--    <template #footer>-->
<!--      <el-button @click="closeConfirmDialog">Отмена</el-button>-->
<!--      <el-button type="primary" @click="confirmCloseModal">Ок</el-button>-->
<!--    </template>-->
<!--  </el-dialog>-->

</template>

<script>
import {ref, onMounted, computed} from 'vue';
import { ElMessage } from 'element-plus';
import {clubInClubGet, clubInClubUpdate} from "features/settings/club-in-club/requests/hubstr";

export default {
  props: {
    tags: {
      type: Array,
      required: true
    },
    entityId: {
      type: String,
      required: true
    }
  },
  setup(props) {
    const clubData = ref({
      id: '',
      title: '',
      url: '',
      description: '',
      image: '',
      tags: []
    });

    const allTags = computed(() => {
      return props.tags;
    });

    const isModalVisible = ref(true); // Управляет видимостью модалки
    const loading = ref(false); // Управляет индикатором загрузки
    const imageAccept = '.jpg,.jpeg,.png'; // Допустимые форматы изображений
    const imagePreview = ref('');
    const confirmDialogVisible = ref(false);
    function openConfirmDialog() {
      confirmDialogVisible.value = true;
    }
    function closeConfirmDialog() {
      confirmDialogVisible.value = false;
    }
    function confirmCloseModal() {
      isModalVisible.value = false;
      closeConfirmDialog()
    }
    const beforeUpload = (file) => {
      const isImage = file.type.startsWith('image/');
      if (!isImage) {
        ElMessage.error('Выберите изображение формата .jpg, .jpeg, .png');
      }
      return isImage;
    }

    const handleChange = (file) => {
      if (file && file.raw) {
        const reader = new FileReader();
        reader.onload = (e) => {
          clubData.value.image.newImage = file.raw;
          imagePreview.value = e.target.result;
        };
        reader.readAsDataURL(file.raw);
      }
    }

    // Получение информации о клубе
    const fetchClubInfo = async () => {
      loading.value = true;
      try {
        const response = await clubInClubGet({ id: props.entityId});
        clubData.value = {
          id: response.data.id,
          title: response.data.title,
          url: response.data.url,
          description: response.data.description,
          image: response.data.image,
          tags: response.data?.tags,
        };
        imagePreview.value = response.data.image?.image
      } catch (error) {
        ElMessage.error('Ошибка при загрузке данных клуба');
      } finally {
        loading.value = false;
      }
    };

    const saveClubData = () => {
      loading.value = true;
      const response = clubInClubUpdate({...clubData.value})
      ElMessage.success('Данные клуба сохранены успешно!');
    };

    const closeModal = () => {
      if (!loading.value) {
        isModalVisible.value = false;
        ElMessage.warning('Редактирование отменено');
      }
    };



    onMounted(() => {
      fetchClubInfo();
    });

    return {
      clubData,
      allTags,
      isModalVisible,
      saveClubData,
      closeModal,
      loading,

      beforeUpload,
      handleChange,
      imageAccept,
      imagePreview,

      confirmDialogVisible,
      closeConfirmDialog,
      confirmCloseModal,
    };
  }
};
</script>

<style scoped>
/* Модальное окно и кнопки */
.el-dialog {
  max-width: 600px;
}

.el-input {
  width: 100%;
}

.el-select {
  width: 100%;
}
</style>