import { LoyaltyProgram } from 'features/profile/loyalty/main/types';
import { request } from '~/utils/request';

let loyaltyProgramList = {} as LoyaltyProgram;

export const getLoyaltyMain = request.card<any>(async ({ api, data, parseError }) => {
  const res = await api.get<{ data: any }>('/api/admin/view?code=loyalty_program');
  if (res.error) {
    return { error: parseError(res.error) };
  }

  loyaltyProgramList = res.data?.data;
  return { 
    data: { 
      text: res.data?.data.widgets.body[0].text, 
    }, 
  };
});

const saveLoyalty = (body: LoyaltyProgram) => {
  const updateLoyalty = request.custom(async ({ api, data, parseError }) => {
    const res = await api.put('/api/admin/view', data);

    if (res.error) {
      return { error: parseError(res.error) };
    }

    return { data: 'ok' };
  });

  return updateLoyalty(body);
};

export const updateLoyaltyMain = request.card(async ({ api, parseError, data }) => {

  if (data.data.text) {
    loyaltyProgramList.widgets.body[0].text = data.data.text;
  }

  const res = await saveLoyalty(loyaltyProgramList as LoyaltyProgram);

  if (res.error) {
    return { error: parseError?.(res.error) };
  }

  return res;
});
