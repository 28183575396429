import {
  actionCard, openModal, tablePageController, translate,
} from 'magner';
import { notificationRead } from 'features/settings/notification/requests/atlanty';
import { CustomNotification } from 'features/settings/notification/types/hubstr';
import notificationTable from 'features/settings/notification/table/hubstr';
import notificationFilters from 'features/settings/notification/filters/hubstr';
import notificationItemConfig from 'features/settings/notification/item/hubstr';

const notificationTableConfig = tablePageController<CustomNotification>({
  header: {
    title: translate('atlanty.notification.table.title'),
    actions: [
      {
        type: 'action',
        props: {
          type: 'primary',
          text: translate('atlanty.notification.filters.create_new'),
        },
        emits: 'update-table',
        action: actionCard<CustomNotification>(async () => {
          try {
            await openModal<any>({
              type: 'card',
              config: notificationItemConfig,
              handleBeforeClose: true,
              customClass: '',
              props: {
                emptyCard: true,
                entityId: null,
              },
            });
            return false;
          } catch (e) {
            return translate('atlanty.notification.form.failed_creation');
          }
        }),
      },
    ],
  },
  request: notificationRead,
  table: notificationTable as any,
  filters: notificationFilters,
});

export default notificationTableConfig;
