import { translationController } from 'magner';
import ruCustom from 'configs/translation/ru';
import enCustom from 'configs/translation/en';
import ruElLocale from 'element-plus/lib/locale/lang/ru';
import enLocale from 'element-plus/lib/locale/lang/en';
import uzLocale from 'element-plus/lib/locale/lang/uz-uz';
import uzCustom from 'configs/translation/uz';
import {
  APP_ID,
  APP_MILLIARD,
} from '~/constants';

// eslint-disable-next-line no-shadow
export enum SupportedLanguages {
  RUS = 'ru',
  ENG = 'en',
  UZB = 'uz'
}

const i18n_milliard = translationController<SupportedLanguages>((() => {
  switch (APP_ID) {
    case APP_MILLIARD: {
      return {
        mainLanguage: 'uz',
        fallbackLanguage: 'ru',
        languages: {
          uz: 'O\'zbek',
          ru: 'Русский',
          en: 'English',
        },
        translation: {
          ru: ruCustom,
          en: enCustom,
          uz: uzCustom,
        },
        elLocales: {
          ru: ruElLocale,
          en: enLocale,
          uz: uzLocale,
        },
      };
    }
    default: {
      return {
        mainLanguage: 'ru',
        fallbackLanguage: 'ru',
        languages: {
          ru: 'Русский',
        },
        translation: {
          ru: ruCustom,
        },
        elLocales: {
          ru: ruElLocale,
        },
      };
    }
  }
})() as any);

export default i18n_milliard;
