import {
  actionTable,
  openModal,
  tablePageController,
} from 'magner';
import chapterTable from 'features/settings/chapter/table/hubstr';
import { chaptersRead } from 'features/settings/chapter/requests/hubstr';
import CreateChapter from './create_chapter.vue';

const chapterConfig: any = tablePageController<any>({
  header: {
    title: 'Список чаптеров',
    actions: [
      {
        type: 'action',
        props: {
          type: 'primary',
          text: 'Создать чаптер',
        },
        emits: 'update-table',
        action: actionTable<any>(async () => {
          try {
            await openModal<any>({
              type: 'custom',
              customClass: 'modal-max-width modal-max-width__500',
              handleBeforeClose: true,
              component: () => CreateChapter,
              props: {},
            });
            return false;
          } catch (e) {
            return 'Не удалось создать чаптер';
          }
        }),
      },
    ],
  },
  request: chaptersRead,
  table: chapterTable as any,
  filters: {
    fieldsShowAmount: 5,
    filtersData: {},
    actions: [],
    layout: [],
  },
});

export default chapterConfig;
