<template>
  <el-row>
    <h2>Привязать к бади-партнеру</h2>
  </el-row>

  <el-row>
    <h4>Выбрано резидентов: {{ residentsList.length }}</h4>
  </el-row>

  <el-row gutter="30">
    <el-col>
      <el-select
        :placeholder="'Выбрать бади'"
        :loading="state.loading"
        :loading-text="'Загрузка'"
        :filterable="true"
        :remote="true"
        :remote-method="remoteMethod"
        :model-value="state.selectedPartner"
        :class="[{
          'select-error': state.error,
        }]"
        @change="selectPartner"
      >
        <el-option
          v-for="option in state.partners"
          :key="option.id"
          :value="option.id"
          :label="option.fio"
        />
      </el-select>
    </el-col>
  </el-row>

  <el-row gutter="30">
    <el-col>
      <el-button
        native-type="button"
        type="primary"
        @click="submitPartner"
      >
        {{ state.selectedPartner === '-1' ? 'Отвязать' : 'Привязать' }}
      </el-button>
    </el-col>
  </el-row>
</template>

<script lang="ts">
import {
  defineComponent, onMounted, PropType, reactive,
} from 'vue';
import { Resident } from 'features/residents/types/hubstr';
import { buddyPartnerChange, buddyPartnersListGet } from 'features/settings/buddy-partners/requests/hubstr';

export default defineComponent({
  name: 'BuddyPartnersEditForm',
  props: {
    residentsList: {
      type: Array as PropType<Resident[]>,
      required: true,
    },
  },
  emits: ['success'],
  setup (props, { emit }) {
    const state: {
      selectedPartner: string,
      loading: boolean,
      partners: Resident[],
      error: boolean,
    } = reactive({
      selectedPartner: '',
      loading: false,
      partners: [],
      error: false,
    });

    const remoteMethod = async (search = '') => {
      state.loading = true;
      const newOptions = await buddyPartnersListGet({ search });
      state.partners = newOptions.data?.rows.filter((item) => item.id !== '') || [];
      state.loading = false;
    };

    const selectPartner = (id: string) => {
      state.error = false;
      state.selectedPartner = id;
    };

    const submitPartner = async () => {
      state.error = false;

      if (state.selectedPartner.length === 0) {
        state.error = true;
        return;
      }

      await buddyPartnerChange({
        residentsList: props.residentsList,
        mentor: state.selectedPartner,
      });

      emit('success');
    };

    onMounted(() => {
      remoteMethod('');
    });

    return {
      state,

      remoteMethod,
      selectPartner,
      submitPartner,
    };
  },
});
</script>

<style scoped>
h4 {
  margin: 0;
}
.el-row {
  margin-bottom: 30px;
}
:deep(.select-error .el-input__inner) {
  box-shadow: 0 0 0 1px #ff3333 inset!important;
}
</style>
