import { cardPageController, translate } from 'magner';
import { hubstrLayout } from 'features/settings/notification/layout/hubstr';
import {
  notificationCreate, notificationDelete,
  notificationGet, notificationNewGet,
  notificationUpdate,
} from 'features/settings/notification/requests/atlanty';
import { CustomNotification } from 'features/settings/notification/types/hubstr';

const notificationItemConfig = cardPageController<CustomNotification>({
  header: {
    title: translate('atlanty.notification.form.title'),
  },

  getRequest: notificationGet,
  getNewRequest: notificationNewGet,
  createRequest: notificationCreate,
  updateRequest: notificationUpdate,
  deleteRequest: notificationDelete,

  confirmDelete: translate('resident.form.confirm_delete'),

  form: {
    fullDataOnUpdate: true,
    actions: [
      {
        type: 'action',
        emits: 'submit',
        props: {
          text: translate('atlanty.notification.form.submit_button'),
          type: 'primary',
          hidden: (data: {state: {status: string}}) => data.state.status === 'completed' || data.state.status === 'failed',
        },
      },
      {
        type: 'action',
        emits: 'remove',
        props: {
          text: translate('atlanty.notification.form.remove_button'),
          type: 'danger',
        },
      },
    ],

    layout: {
      type: 'row',
      props: {},
      layout: hubstrLayout as any,
    },
  },
});

export default notificationItemConfig;
