// @ts-ignore
export const API_URL = window && window.VITE_APP_API_URL as string;
// @ts-ignore
export const APP_ID = window && window.VITE_APP_ID;

// eslint-disable-next-line no-shadow
export enum ROLE {
  ADMIN = 'ROLE_ADMIN'
}

export const APP_HUBSTR = 'hubstr';
export const APP_ATLANTY = 'atlanty';
export const APP_ETALON = 'etalon';
export const APP_UDSCHOOL = 'udschool';
export const APP_COMMUNITY_CENTER = 'community_center';
export const APP_CLOVER = 'clover';
export const APP_TAXI_369 = 'taxi369';
export const APP_GAZPROM = 'gaz';
export const APP_CODE_PILOTS = 'code_pilots';
export const APP_MILLIARD = 'milliard';

export const siteTitle = (appId: string) => {
  switch (appId) {
    case APP_ATLANTY: {
      return 'Бизнес-клуб "Атланты"';
    }
    case APP_ETALON: {
      return 'Бизнес-клуб "Эталон"';
    }
    case APP_UDSCHOOL: {
      return 'Умный Дизайн';
    }
    case APP_COMMUNITY_CENTER: {
      return 'Центр Сообществ';
    }
    case APP_CLOVER: {
      return 'Клевер';
    }
    case APP_TAXI_369: {
      return 'Группа компаний 369';
    }
    case APP_GAZPROM: {
      return 'Газпром. Профсоюз';
    }
    case APP_CODE_PILOTS: {
      return 'Code Pilots';
    }
    case APP_MILLIARD: {
      return 'Миллиард';
    }
    default: {
      return 'Hubstr';
    }
  }
};

// TODO:: move it somewhere else. Don't know where
export const imageUploadCode = 'link_logo';
