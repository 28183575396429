import type { EventGuest } from 'features/events/guests/types/hubstr';
import { translate, useTranslate } from 'magner';
import { TableColumn } from 'magner/lib/types/components/table';
import { formatPhone } from '~/utils/format-date';

const statusFormatter = (status: EventGuest['status']): string => {
  const { customT } = useTranslate();

  return customT(translate(`hubstr.event.guests.statuses.${status}`));
};

const formatOnlineParticipation = (isOnlineParticipation: string | boolean): string => {
  const { customT } = useTranslate();

  return isOnlineParticipation
    ? customT(translate('hubstr.event.guests.online.online'))
    : customT(translate('hubstr.event.guests.online.offline'));
};

export const firstNameField: TableColumn<EventGuest> = {
  prop: 'firstName',
  label: translate('hubstr.event.guests.table.name'),
  width: 200,
};

export const lastNameField: TableColumn<EventGuest> = {
  prop: 'lastName',
  label: translate('hubstr.event.guests.table.surname'),
  width: 200,
};

export const emailField: TableColumn<EventGuest> = {
  prop: 'email',
  label: translate('hubstr.event.guests.table.email'),
  width: 250,
};

export const phoneField: TableColumn<EventGuest> = {
  prop: 'phone',
  label: translate('hubstr.event.guests.table.phone'),
  width: 170,
  view: {
    type: 'text',
    formatter: (_, row) => formatPhone(row.phone),
  },
};

export const chapterField: TableColumn<EventGuest> = {
  prop: 'chapter',
  label: translate('hubstr.event.guests.table.chapter'),
  width: 150,
  view: {
    type: 'text',
    formatter: (_, row) => `${row.chapter ? row.chapter.name : ''}`,
  },
};

export const telegramField: TableColumn<EventGuest> = {
  prop: 'telegram',
  label: translate('hubstr.event.guests.table.telegram'),
};

export const isOnlineParticipationField: TableColumn<EventGuest> = {
  prop: 'isOnlineParticipation',
  label: translate('hubstr.event.guests.table.online'),
  view: {
    type: 'text',
    formatter: (_, row) => formatOnlineParticipation(row.isOnlineParticipation),
  },
};

export const statusField: TableColumn<EventGuest> = {
  prop: 'status',
  label: translate('hubstr.event.guests.table.status'),
  view: {
    type: 'tags',
    formatter: (cell) => statusFormatter(cell as EventGuest['status']),
  },
};
