import { translate } from 'magner';
import { TableColumn } from 'magner/lib/types/components/table';
import { BansList } from 'features/settings/bans/types/hubstr';
import { formatDate } from '~/utils/format-date';

export const banCompany: TableColumn<BansList> = {
  prop: 'ignoring',
  label: translate('hubstr.bans.table.company'),
  view: {
    type: 'text',
    formatter: (_, row) => _.name,
  },
  width: 350,
};

export const banAuthor: TableColumn<BansList> = {
  prop: 'ignored',
  label: translate('hubstr.bans.table.author'),
  view: {
    type: 'text',
    formatter: (_, row) => _.name,
  },
  width: 350,
};

export const banCreatedAt: TableColumn<BansList> = {
  prop: 'created_at',
  label: translate('hubstr.bans.table.created'),
  view: {
    type: 'text',
    formatter: (_, row) => formatDate(_),
  },
  width: 350,
};

export const banActions: TableColumn<BansList> = {
  prop: 'action',
  label: translate('hubstr.bans.table.actions'),
  width: 230,
  view: {
    type: 'actions',
    actions: [
      {
        type: 'action',
        emits: 'open-complaint',
        action: () => true,
        props: {
          text: translate('hubstr.bans.table.remove'),
          type: 'primary',
          plain: true,
        },
      },
    ],
  },
};
