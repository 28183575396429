import {
  actionCard, openModal, tableController, translate,
} from 'magner';
import { Resident, ResidentList } from 'features/residents/types/hubstr';
import BuddyPartnerLink from 'features/settings/buddy-partners/table/buddyPartnerLink.vue';
import BuddyPartnersEditForm from 'features/settings/buddy-partners/layout/hubstr.vue';
import ResidentLink from './residentLink.vue';
import { formatPhone, formatShortDate } from '~/utils/format-date';

const buddyPartnersTable = tableController<ResidentList>({
  tableLayout: 'auto',
  scrollbarAlwaysOn: true,
  emptyText: translate('atlanty.buddy_partner.table.not_found'),
  rowSelectable: {
    rowIdKey: 'id',
    actions: [
      {
        type: 'action',
        props: {
          type: 'primary',
          text: translate('atlanty.buddy_partner.table.change_buddy_partner'),
        },
        emits: 'update-table',
        action: actionCard<any>(async (data) => {
          try {
            await openModal<any>({
              type: 'custom',
              handleBeforeClose: true,
              customClass: 'change-community-manager-modal',
              component: () => BuddyPartnersEditForm,
              props: {
                residentsList: (data.data as unknown as {selected: Resident[]}).selected,
              },
            });
            return false;
          } catch (e) {
            return translate('atlanty.buddy_partner.form.failed_action');
          }
        }),
      },
    ],
  },
  columns: [
    {
      prop: 'avatar',
      label: translate('atlanty.buddy_partner.table.avatar'),
      view: {
        // @ts-ignore
        type: 'image',
        // @ts-ignore
        nestedKey: 'thumb_100',
      },
      width: 90,
      fixed: 'left',
    },
    {
      prop: 'fio',
      label: translate('atlanty.buddy_partner.table.fio'),
      className: 'cm-fio-cell',
      width: 150,
      fixed: 'left',
      view: {
        type: 'custom',
        component: () => ResidentLink,
      },
    },
    {
      prop: 'phone',
      label: translate('atlanty.buddy_partner.table.phone'),
      width: 170,
      view: {
        type: 'text',
        formatter: (_, row) => formatPhone(row.phone),
      },
    },
    {
      prop: 'email',
      label: translate('atlanty.buddy_partner.table.email'),
      width: 250,
    },
    {
      prop: 'birthday',
      label: translate('atlanty.buddy_partner.table.birthday'),
      view: {
        type: 'text',
        formatter: (_, row) => formatShortDate(row.birthday),
      },
      width: 150,
    },
    {
      prop: 'mentor',
      label: translate('atlanty.buddy_partner.table.buddy_partner'),
      view: {
        type: 'custom',
        component: () => BuddyPartnerLink,
      },
    },
    {
      prop: 'status',
      label: translate('atlanty.buddy_partner.table.status'),
      view: {
        type: 'tags',
        formatter: (cellValue) => (cellValue as ResidentList['status']).title,
      },
      width: 100,
    },
  ],
});

export default buddyPartnersTable;
