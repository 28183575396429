import { v4 as uuidv4 } from 'uuid';
import { fileUpload } from 'features/common/file-request';
import { residentSearch } from 'features/residents/requests/hubstr';
import type {
  Category,
  Event,
  EventCreate,
  EventReceive, OrganizerResident,
} from 'features/events/types/hubstr';
import { IInterview } from 'features/settings/interviews/interfaces';
import { request } from '~/utils/request';

import { API_URL } from '~/constants';

interface Pager {
  currentPage: number,
  totalPages: number,
  totalItems: number,
}

/**
 * Получить список мероприятий
 */
export const eventRead = request.table<Event>(async ({ api, data }) => {
  const pagination = `count=${data.pagination.items || 25}&page=${data.pagination.page}`;
  const sort = '&sort[0][id]=dateStart&sort[0][value]=DESC';
  const search = data.filters.name ? `&filters[0][id]=search&filters[0][value]=${data.filters.name}` : '';
  const planned_only = data.filters.status ? '&filters[1][id]=status&filters[1][value]=planned' : '';

  let dateFilter = '';
  if (data.filters.dateStart) {
    const d = new Date(data.filters.dateStart);
    const formattedDate = `${d.getMonth() + 1}.${d.getFullYear()}`;
    dateFilter = `&filters[2][id]=onDate&filters[2][value]=${formattedDate}`;
  }

  let categories = '';
  if (data.filters.categories?.length) {
    const arr = data.filters.categories.map((id: string) => `filters[3][value][]=${id}`) as string[];
    categories = `&filters[3][id]=categories&${arr.join('&')}`;
  }

  const chapterSearch = data.filters.chapter
    ? `&filters[4][id]=chapter&filters[4][value]=${data.filters.chapter}`
    : '';

  const res = await api.get<{ list: Event[], pager: Pager }>(
    `/api/admin/event/list?${pagination}${sort}${search}${dateFilter}${categories}${planned_only}${chapterSearch}`,
  );

  if (res.error) {
    return {
      data: {
        rows: [],
        pagination: null,
      },
    };
  }

  return {
    data: {
      rows: res.data.list,
      pagination: {
        currentPage: res.data.pager?.currentPage || 1,
        totalPages: res.data.pager?.totalPages || 1,
        totalItems: res.data.pager?.totalItems || 0,
      },
    },
  };
});

// const getRegistrOption = (obj: any): string | undefined => {
//   if (obj.isRegistrationOpened) {
//     return obj.isParticipantsOfflineLimitExceeded ? 'register_online' : 'register_open';
//   }

//   if (!obj.isRegistrationOpened && obj.isParticipantsOfflineLimitExceeded) return 'register_limit';

//   if (obj.RegistrationStatus === 'closed') return 'register_close';

//   if (obj.RegistrationStatus === 'suspended') return 'register_stop';

//   if (obj.RegistrationStatus === 'not_started') return 'register_not_start';

//   return undefined;
// };

// const getFormat = (data: any): string | undefined => {
//   if (getRegistrOption(data?.event) === 'register_online' && data?.isRegistrationOpened && data?.isParticipantsOfflineLimitExceeded) {
//     return 'mixed';
//   }

//   if (getRegistrOption(data?.event) === 'register_limit' && data?.isParticipantsOfflineLimitExceeded && !data?.isRegistrationOpened && data.broadcastLink === undefined) {
//     return 'offline';
//   }

//   return 'online';
// };

/**
 * Получить детальную информацию о мероприятии
 */
export const eventGet = request.card<EventReceive, EventCreate>(async ({ api, data, parseError }) => {
  const res = await api.get<{ event: Event }>(`/api/admin/event?event=${data.id}`);
  if (res.error) {
    return { error: parseError(res.error) };
  }

  const page = document.querySelector('.page');
  if (res.data?.event.dateEnd && new Date() > new Date(res.data?.event.dateEnd)) {
    page?.classList.add('event-past');
  } else {
    page?.classList.remove('event-past');
  }

  return {
    data: {
      ...res.data?.event,
      categories: res.data?.event.categories.map((category: Category) => category.id),
      owner: res.data?.event.owner ? res.data.event.owner.id : null,
      address: res.data?.event.address ? res.data.event.address.id : null,
      dateStart: [new Date(res.data?.event.dateStart), new Date(res.data?.event.dateEnd)] as unknown as Date,
      dateEnd: new Date(res.data?.event.dateEnd),
      invitedDepartments: Object.values(res.data?.event.invitedDepartments || {}).map(([id]) => id),
      materialsLink: res.data?.event.additions?.materialsLink,
      publish: res.data?.event.id,
      isPublished: res.data?.event.isPublished,
    },
  };
});
/**
 * формирование запроса на создание/сохранение
 */
const getRequestBody = async (data: any, id: string) => {
  const body = { ...data.data, id };

  if (data.data.dateStart) {
    const date = data.data.dateStart as unknown as [Date, Date];
    body.dateStart = date?.[0];
    body.dateEnd = date?.[1];
  }

  if (data.data.logo && !data.data.logo.id) {
    const fileUploadRes = await fileUpload(
      id as string,
      (data.data.logo as File[])[0],
      'event',
      'event_logo',
      'eventLogo',
    );

    if (fileUploadRes.error) {
      return {
        error: {
          message: '',
          fields: { logo: fileUploadRes.error as unknown as string },
        },
      };
    }
    body.logo = fileUploadRes.data?.id;
  }

  if (data.data.logo && data.data.logo.id) {
    body.logo = data.data.logo.id;
  }

  if (data.data.organizers?.length) {
    const owner = data.data.organizers.find((org) => org.type === 'resident' && org.id) as OrganizerResident;
    body.owner = '';
    if (owner) {
      body.owner = owner.id;
    }
  }

  if (data.data.categories?.length) {
    body.categories = data.data.categories
      .map((category) => (typeof category === 'object' ? (category as any)?.id : category));
  }

  if (typeof data.data.address === 'object') body.address = (body.address as any)?.id || body.address;

  if (data.data.poll?.length === 0) {
    body.poll = null;
  }

  if (data.data.chapter && data.data.chapter.id) {
    body.chapter = data.data.chapter.id;
  }

  if (data.data.format === 'online') {
    body.residentsLimit = undefined;
  }

  if (data.data.format === 'offline') {
    body.broadcastLink = undefined;
  }

  if (typeof data.data.isRegistration === 'string') {
    if (data.data.isRegistration === 'register_not_start') {
      body.isRegistrationOpened = false;
      body.RegistrationStatus = 'not_started';
    }
    if (data.data.isRegistration === 'register_open') {
      body.isRegistrationOpened = true;
    }
    if (data.data.isRegistration === 'register_stop') {
      body.isRegistrationOpened = false;
      body.RegistrationStatus = 'suspended';
    }
    if (data.data.isRegistration === 'register_close') {
      body.isRegistrationOpened = false;
      body.RegistrationStatus = 'closed';
    }
    if (data.data.isRegistration === 'register_online' && data.data.format === 'mixed') {
      body.isRegistrationOpened = true;
      body.isParticipantsOfflineLimitExceeded = true;
    }
    if (data.data.isRegistration === 'register_limit' && data.data.format === 'offline') {
      body.isRegistrationOpened = false;
      body.isParticipantsOfflineLimitExceeded = true;
    }
  }

  return body;
};
/**
 * Создать мероприятие
 */
export const eventCreate = request.card<Event, EventCreate>(async ({
  api, parseError, data, router,
}) => {
  const body = await getRequestBody(data, uuidv4());

  body.additions = {
    materialsLink: data.data.materialsLink?.length ? data.data.materialsLink : '',
  };

  const res = await api.post<{ event: Event }>('/api/admin/event', body);
  if (res.error) {
    return { error: parseError?.(res.error) };
  }

  router.push({ name: 'event', params: { id: res.data?.event.id } });
  return { data: res.data?.event };
});
/**
 * Обновить детальную информацию мероприятия
 */
export const eventUpdate = request.card<Event, EventCreate>(async (props) => {
  const { api, parseError, data } = props;
  const body = await getRequestBody(data, data.id as string);

  body.additions = {
    materialsLink: data.data.materialsLink?.length ? data.data.materialsLink : '',
  };

  const res = await api.patch<{ event: Event }>('/api/admin/event', body);
  if (res.error) {
    return { error: parseError?.(res.error) };
  }
  return { data: res.data?.event };
});
/**
 * Удалить мероприятие
 */
export const eventDelete = request.card(async ({
  api, data, parseError, router,
}) => {
  const res = await api.delete(`/api/admin/event?event=${data.id}`);
  if (res.error) {
    return { error: parseError(res.error) };
  }

  router.push({ name: 'events' });
  return { data: 'ok' };
});
/**
 *
 */
export const getEventReportRating = request.card(async (
  {
    data,
    lstorage,
  },
) => {
  const token = lstorage.read('token');

  // @ts-ignore
  fetch(`${API_URL}/api/admin/event/report/rating?event=${data.id}&format=${data.format}`, {
    method: 'GET',
    headers: {
      Authorization: `Bearer ${token}`,
    },
  })
    .then((res) => res.blob())
    .then((blob) => {
      const url = URL.createObjectURL(blob);
      const a = document.createElement('a');
      a.href = url;
      // @ts-ignore
      a.download = `Статистика_оценок_${data.form.name}.${data.format}`;
      document.body.appendChild(a);
      a.click();
      a.remove();
    });

  return { data: 'ok' };
});
/**
 * опубликовать мероприятие
 */
export const publishEvent = request.custom(async ({
  api, data, parseError, router,
}) => {
  const res = await api.patch<{ event: Event }>('/api/admin/event/publish', { id: data.data.id });
  if (res.error) {
    return { error: parseError?.(res.error) };
  }

  window.location.reload();
  // return { data: res.data?.event };
});
/**
 * Получение списка опросников, не привязанных ни к какому мероприятию
 */
export const getPollList = request.table<IInterview>(async ({ api, data }) => {
  try {
    const res = await api.get<{ list: Event[] }>(
      '/api/admin/poll/list?count=50&page=1',
    );

    if (res.error) {
      return {
        data: {
          rows: [],
          pagination: null,
        },
      };
    }

    return {
      data: {
        rows: res.data.list,
        pagination: null,
      },
    };
  } catch (e) {
    return {
      data: {
        rows: [],
        pagination: null,
      },
    };
  }
});
