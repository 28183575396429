import { translate, filtersFormController } from 'magner';
import type { Resident } from 'features/residents/types/hubstr';
import { clubRoleRead } from 'features/settings/club-roles/requests/hubstr';
import {
  eventChapterFilter,
  residentStatusList,
} from 'features/residents/requests/hubstr';

const residentsFilters = filtersFormController<Resident>({
  actions: [],

  saveToLocalStorage: true,

  submitEvent: 'input',

  pagination: {
    items: 25,
    page: 1,
  },
  fieldsShowAmount: 1,
  filtersInSeparatePanel: true,
  filtersData: {
    firstName: '',
    phone: '',
    positionTags: '',
    status: ['active'],
    communityManagerName: '',
    chapter: null,
    guest: null,
  },
  sort: {},

  layout: [
    {
      type: 'input',
      name: 'firstName',
      props: {
        placeholder: translate('hubstr.resident.filters.name'),
        inputDelay: 250,
      },
    },
    {
      type: 'input',
      name: 'phone',
      props: {
        placeholder: translate('hubstr.resident.filters.phone'),
        inputDelay: 250,
        mask: {
          mask: '+7##########',
        },
      },
    },
    {
      type: 'select',
      name: 'positionTags',
      dataType: 'array',
      options: [],
      props: {
        multiple: false,
        filterable: true,
        remote: true,
        valueKey: 'id',
        labelKey: 'name',
        remoteMethod: clubRoleRead,
        placeholder: translate('hubstr.resident.filters.positionTags'),
      },
    },
    {
      type: 'select',
      name: 'status',
      dataType: 'array',
      options: [],
      props: {
        multiple: true,
        filterable: true,
        remote: true,
        collapseTags: true,
        valueKey: 'code',
        labelKey: 'title',
        remoteMethod: residentStatusList,
        placeholder: translate('hubstr.resident.filters.status'),
      },
    },
    {
      type: 'input',
      name: 'communityManagerName',
      props: {
        placeholder: translate('hubstr.resident.filters.community_manager'),
        inputDelay: 250,
      },
    },
    {
      type: 'select',
      name: 'chapter',
      dataType: 'array',
      options: [],
      props: {
        multiple: false,
        filterable: false,
        remote: true,
        collapseTags: true,
        valueKey: 'id',
        labelKey: 'name',
        remoteMethod: eventChapterFilter,
        placeholder: translate('hubstr.event.filters.chapter'),
      },
    },
    {
      type: 'select',
      name: 'guest',
      dataType: 'array',
      options: [
        {
          label: 'Да',
          value: true,
        },
        {
          label: 'Нет',
          value: false,
        },
      ],
      props: {
        placeholder: translate('hubstr.event.filters.guest'),
      },
    },
  ],
});

export default residentsFilters;
