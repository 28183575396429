import { cardPageController, translate } from 'magner';
import {
  clubInClubCreate, clubInClubGet, clubInClubDelete, clubInClubUpdate,
} from 'features/settings/club-in-club/requests/hubstr';
import type { ClubInClubFull } from 'features/settings/club-in-club/types/hubstr';

const clubInClubConfig = cardPageController<ClubInClubFull>({
  getRequest: clubInClubGet,
  createRequest: clubInClubCreate,
  updateRequest: clubInClubUpdate,
  deleteRequest: clubInClubDelete,

  header: {
    title: translate('hubstr.club_in_club.form.title_create'),
  },

  form: {
    actions: [
      {
        type: 'action',
        emits: 'submit',
        props: {
          type: 'primary',
          text: translate('hubstr.club_in_club.form.submit_button'),
        },
      },
      {
        type: 'action',
        emits: 'remove',
        props: {
          type: 'danger',
          text: translate('hubstr.club_in_club.form.remove_button'),
        },
      },
    ],
    layout: {
      type: 'column',
      props: {},
      fields: [
        {
          type: 'input',
          name: 'title',
          label: translate('hubstr.club_in_club.form.name.label'),
          props: {
            required: true,
            placeholder: translate('hubstr.club_in_club.form.name.placeholder'),
          },
          validation: [{
            type: 'empty',
            trigger: 'blur',
          }],
        },

        {
          type: 'input',
          name: 'url',
          label: translate('hubstr.club_in_club.form.url.label'),
          props: {
            required: true,
            placeholder: translate('hubstr.club_in_club.form.url.placeholder'),
          },
          validation: [{
            type: 'empty',
            trigger: 'blur',
          }, {
            type: 'url' as 'empty',
            trigger: 'blur',
          }],
        },

        {
          type: 'textarea',
          name: 'description',
          label: translate('hubstr.club_in_club.form.description.label'),
          props: {
            placeholder: translate('hubstr.club_in_club.form.description.placeholder'),
            maxLength: 500,
            showLetterLimit: true,
            rows: 5,
          },
        },

        {
          type: 'dropzone',
          name: 'image',
          dataType: 'number',
          label: translate('hubstr.club_in_club.form.image.label'),
          props: {
            valueKey: 'id',
            srcKey: 'image',
            removable: true,
          },
        },
      ],
    },
  },
});

export default clubInClubConfig;
