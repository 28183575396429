import { translate } from 'magner';
import {
  accessField,
  addressField, broadcastLinkField,
  categoriesField,
  dateStartField,
  descriptionField,
  isRegistrationOpenedField,
  logoField,
  nameField, reportField,
  residentsLimitField,
  statusField,
  typeField,
} from 'features/events/layouts/general';
import { residentSearch } from 'features/residents/requests/udschool';
import { Resident } from 'features/residents/types/udschool';

export const udschoolLayout = [
  /** Left column */
  {
    type: 'column',
    props: {
      span: 12,
      xs: 24,
      styles: { 'padding-right': '12px' },
    },
    layout: [
      {
        type: 'row',
        props: {
          elementsGrow: true,
        },
        fields: [nameField],
      },

      /** dateStart-dateEnd in one field */
      {
        type: 'row',
        props: {
          elementsGrow: true,
          class: 'date-picker-row',
        },
        fields: [dateStartField],
      },

      {
        type: 'column',
        props: {
          span: 24,
        },
        fields: [
          {
            type: 'select',
            name: 'owner',
            label: translate('hubstr.event.form.organizer.label'),
            options: [],
            props: {
              placeholder: translate('hubstr.event.form.organizer.placeholder'),
              required: true,
              filterable: true,
              clearable: true,
              remote: true,
              valueKey: 'id',
              labelKey: 'firstName',
              remoteMethod: residentSearch,
              labelFormatter: (option: Resident) => `${(option as Resident).firstName} ${(option as Resident).lastName}`,
            },
            validation: [{
              type: 'empty-required' as 'empty',
              trigger: 'change',
            }],
          },
          descriptionField,
          logoField,
        ],
      },
    ],
  },

  /** Right column */
  {
    type: 'column',
    props: { span: 12, xs: 24, styles: { 'padding-left': '12px' } },
    fields: [
      addressField,
      accessField,
      typeField,
      categoriesField,
      statusField,
      isRegistrationOpenedField,
      residentsLimitField,
      reportField,
      broadcastLinkField,
      {
        type: 'input',
        name: 'isPublished',
        props: {
          disabled: true,
          hidden: true,
        },
      },
    ],
  },
];
