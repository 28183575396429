import {
  autocompleteDepartment,
  autocompleteSearch,
  eventChapterSearch,
  residentSearch,
  residentStatusList,
  uploadVideo,
} from 'features/residents/requests/hubstr';
import { UserRoles } from 'features/residents/types/hubstr';
import { clubRoleRead } from 'features/settings/club-roles/requests/hubstr';
import { translate } from 'magner';
import CommunityManagerLink from 'features/residents/resident/components/communityManagerLink.vue';

/**
 * Фамилия резидента
 */
export const lastNameField = {
  type: 'input',
  name: 'lastName',
  label: translate('hubstr.resident.form.last_name.label'),
  dataType: 'string',
  props: {
    required: true,
    disabled: true,
    placeholder: translate('hubstr.resident.form.last_name.placeholder'),
  },
  validation: [{
    type: 'empty-required' as 'empty',
    trigger: 'blur',
  }],
};
/**
 * Имя резидента
 */
export const firstNameField = {
  type: 'input',
  name: 'firstName',
  label: translate('hubstr.resident.form.first_name.label'),
  dataType: 'string',
  props: {
    required: true,
    disabled: true,
    placeholder: translate('hubstr.resident.form.first_name.placeholder'),
  },
  validation: [{
    type: 'empty-required' as 'empty',
    trigger: 'blur',
  }],
};
/**
 * E-mail резидента
 */
export const emailField = {
  type: 'input',
  name: 'email',
  label: translate('hubstr.resident.form.email.label'),
  props: {
    type: 'email',
    disabled: true,
    placeholder: translate('hubstr.resident.form.email.placeholder'),
  },
  validation: [
    {
      type: 'email-required' as 'email',
      trigger: 'blur',
    },
  ],
};
/**
 * Телефон резидента
 */
export const phoneField = {
  type: 'input',
  name: 'phone',
  label: translate('hubstr.resident.form.phone.label'),
  dataType: 'string',
  props: {
    required: true,
    disabled: true,
    placeholder: translate('hubstr.resident.form.phone.placeholder'),
    mask: {
      mask: '+#*',
    },
  },
  validation: [
    {
      type: 'empty-required' as 'empty',
      trigger: 'blur',
    },
    {
      type: 'phone',
      trigger: 'blur',
    },
  ],
};
/**
 * Статус резидента
 */
export const statusField = {
  type: 'select',
  name: 'status',
  label: translate('hubstr.resident.form.status.label'),
  options: [],
  props: {
    hidden: (data: { state: {status: boolean} }) => !data.state.status,
    remote: true,
    disabled: true,
    remoteMethod: residentStatusList,
    valueKey: 'code',
    labelKey: 'title',
  },
};
/**
 * Роль резидента
 */
export const roleField = {
  type: 'select',
  name: 'roles',
  label: translate('hubstr.resident.form.roles.label'),
  options: [
    {
      value: UserRoles.Resident,
      label: translate('hubstr.resident.form.roles.resident'),
    },
    {
      value: UserRoles.Admin,
      label: translate('hubstr.resident.form.roles.admin'),
    },
  ],
  props: {
    required: true,
    placeholder: translate('hubstr.resident.form.roles.placeholder'),
  },
  validation: [{
    type: 'empty-required' as 'empty',
    trigger: 'change',
  }],
};
/**
 * Пароль для админ-панели
 */
export const passwordField = {
  type: 'input',
  name: 'password',
  label: translate('hubstr.resident.form.password.label'),
  props: {
    required: true,
    hidden: (data: { state: {roles: string, roleCurrent: string} }) => !data.state.roles
        || data.state.roles !== UserRoles.Admin
        || (data.state.roles === UserRoles.Admin && data.state.roles !== data.state.roleCurrent),
    placeholder: translate('hubstr.resident.form.password.placeholder'),
  },
};
export const passwordFieldRequired = {
  type: 'input',
  name: 'password',
  label: translate('hubstr.resident.form.password.label'),
  props: {
    required: true,
    hidden: (data: { state: {roles: string, roleCurrent: string} }) => !data.state.roles
      || data.state.roles !== UserRoles.Admin
      || (data.state.roles === UserRoles.Admin && data.state.roles === data.state.roleCurrent),
    placeholder: translate('hubstr.resident.form.password.placeholder'),
  },
  validation: [{
    type: 'empty-required' as 'empty',
    trigger: 'change',
  }],
};
/**
 * Комьюнити менеджер
 */
export const communityManager = {
  type: 'custom',
  name: 'communityManager',
  component: () => CommunityManagerLink,
  props: {
    hidden: (data: { state: {communityManager: any} }) => !data.state.communityManager,
  },
};
/**
 * Аватар резидента
 */
export const avatarField = {
  type: 'column',
  props: {
    span: 8,
    xs: 24,
    styles: { 'padding-right': '12px' },
  },
  fields: [
    {
      type: 'dropzone',
      name: 'avatar',
      label: translate('hubstr.resident.form.avatar.label'),
      props: {
        valueKey: 'id',
        srcKey: 'thumb_100',
        removable: true,
      },
    },
  ],
};
/**
 * Бейдж
 */
export const badgeField = {
  type: 'row',
  props: { elementsGrow: true },
  fields: [
    {
      type: 'textarea',
      name: 'badge',
      label: translate('hubstr.resident.form.badge.label'),
      props: {
        placeholder: translate('hubstr.resident.form.badge.placeholder'),
        rows: 2,
      },
      validation: [{
        type: 'max-length-150' as 'empty',
        trigger: 'change',
      }],
    },
  ],
};
/**
 * Участник с
 */
export const dateOfEntryField = {
  type: 'row',
  props: { justify: 'space-between', elementsGrow: true },
  fields: [
    {
      type: 'datetime',
      name: 'dateOfEntry',
      dataType: 'date',
      label: translate('hubstr.resident.form.entry.label'),
      props: {
        placeholder: translate('hubstr.resident.form.entry.placeholder'),
        format: 'DD.MM.YYYY',
        type: 'date',
        disabled: true,
      },
    },
  ],
};
/**
 *
 */
export const recommendationUserField = {
  type: 'select',
  name: 'recommendationUser',
  options: [],
  label: translate('hubstr.resident.form.recommendation_user.label'),
  props: {
    placeholder: translate('hubstr.resident.form.recommendation_user.placeholder'),
    valueKey: 'id',
    labelKey: 'name',
    filterable: true,
    remote: true,
    clearable: true,
    remoteMethod: residentSearch,
  },
};
/**
 * Форум-группа
 */
export const forumGroup = {
  type: 'input',
  name: 'form_group',
  label: translate('hubstr.resident.form.forum_group.label'),
  props: {
    placeholder: translate('hubstr.resident.form.forum_group.placeholder'),
    disabled: true,
  },
};
/**
 * Роль в клубе
 */
export const positionTags = {
  type: 'select',
  name: 'positionTags',
  dataType: 'array',
  options: [],
  label: translate('hubstr.resident.form.position_tag.label'),
  props: {
    placeholder: translate('hubstr.resident.form.position_tag.placeholder'),
    valueKey: 'id',
    labelKey: 'name',
    multiple: true,
    filterable: true,
    remote: true,
    remoteMethod: clubRoleRead,
  },
};
/**
 * Чаптер
 */
export const chapterField = {
  type: 'select',
  name: 'chapter',
  label: translate('hubstr.resident.form.chapter.label'),
  options: [],
  props: {
    required: true,
    multiple: false,
    placeholder: translate('hubstr.resident.form.chapter.placeholder'),
    filterable: true,
    remote: true,
    valueKey: 'id',
    labelKey: 'name',
    disabled: true,
    remoteMethod: eventChapterSearch,
  },
  validation: [{
    type: 'empty-required' as 'empty',
    trigger: 'change',
  }],
};
/**
 * Отделение
 */
export const departmentField = {
  type: 'select',
  name: 'department',
  options: [],
  label: translate('hubstr.resident.form.department.label'),
  props: {
    required: true,
    placeholder: translate('hubstr.resident.form.department.placeholder'),
    valueKey: 'id',
    labelKey: 'name',
    filterable: true,
    disabled: true,
    remote: true,
    remoteMethod: autocompleteDepartment,
  },
  validation: [{
    type: 'empty-required' as 'empty',
    trigger: 'change',
  }],
};
/**
 * Отделение для Code Pilots
 */
export const departmentCodePilotsField = {
  type: 'select',
  name: 'department',
  options: [],
  label: translate('hubstr.resident.form.department.label'),
  props: {
    required: true,
    placeholder: translate('hubstr.resident.form.department.placeholder'),
    filterable: true,
    remote: true,
    disabled: true,
    remoteMethod: autocompleteSearch('department'),
  },
  validation: [{
    type: 'empty-required' as 'empty',
    trigger: 'change',
  }],
};
/**
 * Город
 */
export const cityField = {
  type: 'select',
  name: 'city',
  options: [],
  label: translate('hubstr.resident.form.city.label'),
  props: {
    multiple: true,
    filterable: true,
    remote: true,
    disabled: true,
    remoteMethod: autocompleteSearch('city'),
    placeholder: translate('hubstr.resident.form.city.placeholder'),
  },
};
/**
 * Дата рождения
 */
export const birthdayField = {
  type: 'datetime',
  dataType: 'date',
  name: 'birthday',
  label: translate('hubstr.resident.form.birthday.label'),
  props: {
    type: 'date',
    placeholder: translate('hubstr.resident.form.birthday.placeholder'),
    format: 'DD.MM.YYYY',
    disabled: true,
  },
  validation: [{
    type: 'birthday' as 'empty',
    trigger: 'blur',
  }],
};
/**
 * Пол
 */
export const sexField = {
  type: 'radio',
  name: 'sex',
  options: [
    {
      label: translate('hubstr.resident.form.gender.option_m'),
      value: true,
    },
    {
      label: translate('hubstr.resident.form.gender.option_f'),
      value: false,
    },
  ],
  label: translate('hubstr.resident.form.gender.label'),
  props: {
    disabled: true,
  },
};
/**
 * Гостевой месяц
 */
export const guestField = {
  type: 'checkbox',
  name: 'onGuestMonth',
  dataType: 'array',
  changeAction: ({ form }: any) => {
    if (Array.isArray(form.onGuestMonth)) {
      const splitUrl = window.location.pathname.split('/residents/');

      localStorage.setItem(
        splitUrl[1] === 'new' ? 'new_resident' : splitUrl[1],
        form.onGuestMonth.length ? 'true' : 'false',
      );
    }
  },
  options: [{
    label: translate('hubstr.resident.form.guest'),
    value: true,
  }],
  props: {
    placeholder: translate('hubstr.resident.form.guest'),
    disabled: true,
  },
};
/**
 * Образование
 */
export const educationField = {
  type: 'input',
  name: 'education',
  label: translate('hubstr.resident.form.education.label'),
  props: {
    placeholder: translate('hubstr.resident.form.education.placeholder'),
    disabled: true,
  },
};
/**
 * Семейный статус
 */
export const familyStatusField = {
  type: 'input',
  name: 'familyStatus',
  label: translate('hubstr.resident.form.family_status.label'),
  props: {
    placeholder: translate('hubstr.resident.form.family_status.placeholder'),
    disabled: true,
  },
};
/**
 * Дети
 */
export const childrenField = {
  type: 'input',
  name: 'children',
  label: translate('hubstr.resident.form.children.label'),
  props: {
    placeholder: translate('hubstr.resident.form.children.placeholder'),
    disabled: true,
  },
};
/**
 * факты о себе
 */
export const factsAboutMeField = {
  type: 'textarea',
  name: 'factsAboutMe',
  label: translate('hubstr.resident.form.facts_about_me.label'),
  props: {
    rows: 3,
    placeholder: translate('hubstr.resident.form.facts_about_me.placeholder'),
    disabled: true,
  },
};
/**
 * Чем я могу быть полезен
 */
// export const provideResourcesField = {
//   type: 'textarea',
//   name: 'provide_resources',
//   label: translate('hubstr.resident.form.provide_resources.label'),
//   props: {
//     rows: 3,
//     placeholder: translate('hubstr.resident.form.provide_resources.placeholder'),
//   },
// };
/**
 * В чем я нуждаюсь в настоящий момент
 */
export const lookResourcesField = {
  type: 'textarea',
  name: 'look_resources',
  label: translate('hubstr.resident.form.look_resources.label'),
  props: {
    rows: 3,
    placeholder: translate('hubstr.resident.form.look_resources.placeholder'),
    disabled: true,
  },
};
/**
 * Интересы
 */
export const interestsField = {
  type: 'select',
  name: 'interests',
  label: translate('hubstr.resident.form.interests.label'),
  options: [],
  props: {
    multiple: true,
    filterable: true,
    allowCreate: true,
    remote: true,
    remoteMethod: autocompleteSearch('interests'),
    placeholder: translate('hubstr.resident.form.interests.placeholder'),
    disabled: true,
  },
};
/**
 * Персональный сайт
 */
export const personalWwwField = {
  type: 'input',
  name: 'personalWww',
  label: translate('hubstr.resident.form.personal_www.label'),
  props: {
    placeholder: translate('hubstr.resident.form.personal_www.placeholder'),
    disabled: true,
  },
  validation: [{
    type: 'url' as 'empty',
    trigger: 'blur',
  }],
};
/**
 * Ссылка на видео-презентацию
 */
export const videoPresentationField = {
  type: 'input',
  name: 'videoPresentation',
  label: translate('hubstr.resident.form.video_presentation.label'),
  props: {
    required: (data: { state: { roles: string; roleCurrent: string } }) => data.state.roles !== UserRoles.Admin
      && data.state.roles !== UserRoles.Team
      && data.state.roles !== UserRoles.CommunityManager,
    placeholder: translate('hubstr.resident.form.video_presentation.placeholder'),
  },
  validation: [{
    type: 'url' as 'empty',
    trigger: 'blur',
  }],
};
/**
 * Загрузка файла на видео-презентацию
 */
export const videoPresentationUploadField = {
  type: 'dropzone',
  name: 'video',
  label: 'Загрузка видеовизитки',
  props: {
    saveToBackend: uploadVideo,
    formats: ['mp4', 'mov', 'avi', 'mkv', 'wmv', 'FLV', 'MP4', 'MOV', 'AVI', 'MKV', 'WMV', 'FLV'],
    hidden: (_) => _.state.videoPresentation,
  },
};
/**
 * Telegram
 */
export const telegramField = {
  type: 'input',
  name: 'telegram',
  label: translate('hubstr.resident.form.telegram.label'),
  dataType: 'string',
  props: {
    placeholder: translate('hubstr.resident.form.telegram.placeholder'),
    disabled: true,
  },
};
/**
 * Telegram ID
 */
export const telegramIdField = {
  type: 'input',
  name: 'externalId',
  label: translate('hubstr.resident.form.telegramId.label'),
  dataType: 'string',
  props: {
    placeholder: translate('hubstr.resident.form.telegramId.placeholder'),
    disabled: true,
  },
};
/**
 * Facebook
 */
export const facebookField = {
  type: 'input',
  name: 'facebook',
  label: translate('hubstr.resident.form.facebook.label'),
  dataType: 'string',
  props: {
    placeholder: translate('hubstr.resident.form.facebook.placeholder'),
    disabled: true,
  },
};
/**
 * Instagram
 */
export const instagramField = {
  type: 'input',
  name: 'instagram',
  label: translate('hubstr.resident.form.instagram.label'),
  dataType: 'string',
  props: {
    placeholder: translate('hubstr.resident.form.instagram.placeholder'),
    disabled: true,
  },
};
/**
 * Экспертиза
 */
export const expertiseField = {
  type: 'textarea',
  name: 'expertise',
  label: translate('hubstr.resident.form.expertise.label'),
  props: {
    rows: 3,
    placeholder: translate('hubstr.resident.form.expertise.placeholder'),
    disabled: true,
  },
};
/**
 * Достижения
 */
export const achievementsField = {
  type: 'textarea',
  name: 'achievements',
  label: translate('hubstr.resident.form.achievements.label'),
  props: {
    rows: 3,
    placeholder: translate('hubstr.resident.form.achievements.placeholder'),
    disabled: true,
  },
};
/**
 * Стаж
 */
export const businessStartYearField = {
  type: 'datetime',
  name: 'business_start_year',
  label: translate('hubstr.resident.form.business_start_year.label'),
  dataType: 'date',
  props: {
    type: 'date',
    format: 'DD.MM.YYYY',
    placeholder: translate('hubstr.resident.form.business_start_year.placeholder'),
    disabled: true,
  },
};
/**
 * Оборот в год, рубли
 */
export const turnoverPerYearField = {
  type: 'input',
  name: 'turnover_per_year',
  dataType: 'number',
  label: translate('hubstr.resident.form.turnover_per_year.label'),
  props: {
    placeholder: translate('hubstr.resident.form.turnover_per_year.placeholder'),
    disabled: true,
  },
};
/**
 * Кол-во сотрудников
 */
export const numberOfEmployeesField = {
  type: 'input',
  name: 'number_of_employees',
  dataType: 'number',
  label: translate('hubstr.resident.form.number_of_employees.label'),
  props: {
    placeholder: translate('hubstr.resident.form.number_of_employees.placeholder'),
    disabled: true,
  },
};
/**
 * Отрасли бизнеса
 */
export const industriesField = {
  type: 'select',
  name: 'industries',
  options: [],
  label: translate('hubstr.resident.form.business_sectors.label'),
  props: {
    multiple: true,
    filterable: true,
    remote: true,
    remoteMethod: autocompleteSearch('industries'),
    placeholder: translate('hubstr.resident.form.business_sectors.placeholder'),
    disabled: true,
  },
};
