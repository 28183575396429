<template>
  <el-row style="width: 100%;padding: 20px 0">
    <el-col>
      <el-input-number
        v-model="intLimit"
        :class="[{'input_error': error && showErrors}]"
        :disabled="onlyMeta"
        min="2"
        @update:modelValue="emitData"
      />
    </el-col>
  </el-row>
</template>

<script lang="ts">
import {
  defineComponent, onMounted, PropType, ref, watch,
} from 'vue';
import { IStarsQuestion } from 'features/settings/interviews/interfaces';

export default defineComponent({
  name: 'StarsQuestion',
  props: {
    data: {
      type: Object as PropType<IStarsQuestion>,
      required: true,
    },
    showErrors: Boolean,
    onlyMeta: Boolean,
  },
  emits: ['update:data'],
  setup (props, { emit }) {
    const intLimit = ref(props.data.intLimit ?? 0);
    const error = ref(false);

    watch(() => props.data, () => {
      intLimit.value = props.data.intLimit ?? 0;
    });

    const emitData = () => {
      error.value = intLimit.value < 2;

      emit('update:data', {
        data: { intLimit: intLimit.value },
        errors: error.value,
      });
    };

    onMounted(() => emitData());

    return {
      error,
      intLimit,

      emitData,
    };
  },
});
</script>
