const MONTH = ['января', 'февраля', 'марта', 'апреля', 'мая', 'июня',
  'июля', 'августа', 'сентября', 'октября', 'ноября', 'декабря'];

export const roundToTwoSigns = (num: number|string): string => {
  if (!num) {
    return '00';
  }

  if (num.toString().length === 1) {
    return `0${num}`;
  }

  return num.toString();
};

/**
 * Функция возвращает дату в формате DD MMMM YYYY HH:mm МСК.
 * Это выглядит так: 3 августа 2021 06:36
 */
export const formatDate = (date: string|Date): string => {
  const d = new Date(date);

  return `${roundToTwoSigns(d.getDate())}.${roundToTwoSigns(d.getMonth() + 1)}.${d.getFullYear()} ${roundToTwoSigns(d.getHours())}:${roundToTwoSigns(d.getMinutes())}`;
};

/**
 * Функция возвращает дату в формате DD.MM.YYYY.
 * Это выглядит так: 3 августа 2021
 */
export const formatShortDate = (date: string|Date): string => {
  if (!date) return '';
  const d = new Date(date);

  return `${roundToTwoSigns(d.getDate())}.${roundToTwoSigns(d.getMonth() + 1)}.${d.getFullYear()}`;
};

/**
 * Функция возвращает телефон в формате +7 (999) 999-99-99
 */
export const formatPhone = (phone: string): string => {
  if (!phone) {
    return '';
  }

  return phone.replace(/(\d{1})(\d{3})(\d{3})(\d{2})(\d{2})/, '+$1 ($2) $3-$4-$5');
};
