import { User } from 'features/login/requests';
import { request } from '~/utils/request';

const profileRequest = request.profile(async ({ api, parseError, lstorage }) => {
  const res = await api.get<{ result: User, token: string }>('/api/admin/profile');
  if (res.error) {
    lstorage.delete('token');
    api.instance.defaults({ headers: {} }, false);
    return { error: parseError(res.error) };
  }

  return {
    data: {
      user: res.data?.result,
      role: res.data?.result.roles?.[0],
    },
  };
});

export default profileRequest;
