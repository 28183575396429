import buddyPartnersTable from 'features/settings/buddy-partners/table/hubstr';
import buddyPartnersFilters from 'features/settings/buddy-partners/filters/hubstr';
import { tablePageController, translate } from 'magner';
import { Resident } from 'features/residents/types/hubstr';
import { residentRead } from 'features/residents/requests/hubstr';

const buddyPartnersConfig = tablePageController<Resident>({
  header: {
    title: translate('atlanty.buddy_partner.table.title'),
  },
  request: residentRead,
  table: buddyPartnersTable as any,
  filters: buddyPartnersFilters,
});

export default buddyPartnersConfig;
