import { tableController, translate } from 'magner';
import {
  banCompany, banAuthor, banCreatedAt, banActions,
} from 'features/settings/bans/table/general';
import { BansList } from 'features/settings/bans/types/hubstr';

const bansTable = tableController<BansList>({
  tableLayout: 'auto',
  scrollbarAlwaysOn: true,
  emptyText: translate('hubstr.bans.table.not_found'),
  rowLink: () => ({ name: 'bans' }),
  columns: [
    banCompany,
    banAuthor,
    banCreatedAt,
    banActions,
  ],
});

export default bansTable;
