<template>
  <el-row>
    <h2>{{ state.update ? "Изменить чаптер" : "Создание нового чаптера" }}</h2>
  </el-row>

  <el-row gutter="30">
    <el-col>
      <el-input
        v-model="state.name"
        :placeholder="'Имя чаптера'"
      />
    </el-col>
  </el-row>
  <el-row gutter="30">
    <el-col>
      <el-input-number
        v-model="state.weight"
        :placeholder="'Вес чаптера'"
      />
    </el-col>
  </el-row>

  <el-row gutter="30">
    <el-col>
      <el-button native-type="button" type="primary" @click="submitFormat">
        {{ state.update ? "Обновить" : "Создать" }}
      </el-button>
    </el-col>
  </el-row>
</template>

<script lang="ts">
import { defineComponent, reactive } from 'vue';
import { createChapter, updateChapter } from '../requests/hubstr';

export default defineComponent({
  name: 'CreateChapter',
  props: {
    row: {
      type: Object,
      required: true,
    },
    update: {
      type: Boolean,
      required: false,
    },
  },
  emits: ['success'],
  setup(props: any, { emit }) {
    const state: {
      name: string | null;
      weight: number | null;
      loading: boolean;
      update?: boolean;
    } = reactive({
      name: props.row.name || null,
      weight: props.row.weight || null,
      update: props.update || false,
      loading: false,
    });

    const submitFormat = async () => {
      if (state.update) {
        await updateChapter({
          name: state.name,
          weight: state.weight,
          chapterid: props.row.id,
        });
      }

      if (!state.update) {
        await createChapter({
          name: state.name,
          weight: state.weight,
        });
      }

      emit('success');

      window.location.reload();
    };

    return {
      state,

      submitFormat,
    };
  },
});
</script>
