import {
  tablePageController, translate, actionCard, openModal, actionTable, action,
} from 'magner';
import { clubInClubRead } from 'features/settings/club-in-club/requests/hubstr';
import type { ClubInClub } from 'features/settings/club-in-club/types/hubstr';
import clubRoleConfig from 'features/settings/club-in-club/club/hubstr';
import {getTags} from "features/settings/tags/requests/apiClient";
import ClubEditForm from "features/settings/club-in-club/club/layout/clubEditModal.vue";
import {useModal} from "element-plus";


let tagsResponse: any; // Объявляем переменную для хранения тегов

const clubsInClubConfig = tablePageController<ClubInClub>({
  request: async () => {
    try {
      const [clubsResponse, tags] = await Promise.all([
        clubInClubRead(), // Первый запрос
        getTags(),        // Второй запрос
      ]);

      tagsResponse = await tags; // Сохраняем тегов в переменной
      return clubsResponse; // Возвращаем ответ по клубам
    } catch (error) {
      console.error("Error fetching data:", error);
      throw error;
    }
  },

  header: {
    title: translate('hubstr.club_in_club.table.title'),
    actions: [
      {
        type: 'action',
        props: {
          type: 'primary',
          text: translate('hubstr.club_in_club.filters.create_new'),
        },
        emits: 'update-table',
        action: actionCard<ClubInClub>(async () => {
          try {
            await openModal<any>({
              type: 'card',
              config: clubRoleConfig,
              handleBeforeClose: true,
              customClass: 'modal-max-width modal-max-width__400',
              props: {
                emptyCard: true,
                entityId: null,
              },
            });
            return false;
          } catch (e) {
            return translate('hubstr.club_in_club.form.failed_creation');
          }
        }),
      },
    ],
  },

  filters: {
    fieldsShowAmount: 5,
    filtersData: {},
    actions: [],
    layout: [],
  },

  table: {
    tableLayout: 'auto',
    scrollbarAlwaysOn: true,
    emptyText: '',
    columns: [
      {
        prop: 'title',
        width: 'auto',
        label: translate('hubstr.club_in_club.table.name'),
      },
      {
        prop: 'id',
        label: translate('hubstr.club_in_club.table.actions'),
        width: 200,
        view: {
          type: 'actions',
          actions: [
            {
              type: 'action',
              emits: 'update-table',
              action: actionTable<ClubInClub>(async ({ data }) => {
                const role = (data as any).row as ClubInClub;
                try {
                  await openModal<any>({
                    type: 'custom',
                    // config: {
                    //   ...clubRoleConfig,
                    //   header: {
                    //     title: translate('hubstr.club_in_club.form.title_edit'),
                    //   },
                    // },
                    // customClass: 'modal-max-width modal-max-width__400',
                    // handleBeforeClose: true,
                    component: () => ClubEditForm,
                    props: {
                      entityId: role.id,
                      tags: tagsResponse.data.rows, // Передаем tags в props модального окна
                    },
                  });
                  return false;
                } catch (e) {
                  return translate('hubstr.club_in_club.form.failed_edition');
                }
              }),
              props: {
                text: translate('hubstr.club_in_club.table.edit_action'),
                type: 'primary',
                plain: true,
              },
            },
          ],
        },
      },
    ],
  },
});

export default clubsInClubConfig;
