import type { Company, CompanyData } from 'features/residents/companies/types/hubstr';

// eslint-disable-next-line no-shadow
export enum UserRoles {
  Admin = 'ROLE_ADMIN',
  Resident = 'ROLE_USER',
  CommunityManager ='ROLE_COMMUNITY_MANAGER',
  Mentor = 'ROLE_MENTOR',
  Moderator = 'ROLE_MODERATOR',
  Proforg = 'ROLE_PROFORG',
  Team = 'ROLE_TEAM_CLUB'
}

export type UserStatus = Record<string, string>;

export interface Tag {
  id: string,
  name: string,
  color: string,
}

export interface Department {
  id: string,
  name: string,
}

export interface Resident {
  id: string,
  fio?: string,
  communityManagerName?: string,
  firstName: string,
  lastName: string,

  status: string,
  roles: UserRoles[],
  acceptRules: null,
  positionTags: string[],
  department: Department | null,

  avatar: File[] | string | null,
  birthday: Date,
  sex: boolean,

  phone: string,
  email: string,
  facebook: string,
  instagram: string,
  telegram: string,

  createdAt: Date,
  dateOfEntry: Date,

  companies: Company[],

  chapter?: {
    id: string,
    name: string
  },

  guest?: string,

  additions: {
    businessStartYear: Date,
    atlas: number,
    badge: string,
    turnoverPerYear: number,
    numberOfEmployees: number,
    education: string,
    interests: string,
    personalWww: string,
    videoPresentation: string,
    form_group: string,
    city: string,
    yearTarget: string,
    factsAboutMe: string,
    expertise: string,
    provideResources: string,
    achievements: string,
    lookResources: string,
    request: string,
    industries: string,
    familyStatus: string,
    children: string,
    description?: string,
  }
  onGuestMonth?: boolean | null,
  communityManager?: {
    id: string,
    firstName: string,
    lastName: string,
  }
  mentor?: {
    id: string,
    firstName: string,
    lastName: string,
  }
  recommendationUser?: {
    id: string,
    firstName: string,
    lastName: string
  }
}

export interface ResidentList {
  id: string,
  fio?: string,
  firstName: string,
  lastName: string,
  phone: string,
  email: string,
  positionTags: Tag[]
  birthday: Date,
  dateOfEntry: Date,
  status: UserStatus,
  chapter?: {
    id: string,
    name: string
  },
  avatar: {
    big: string,
    id: string,
    thumb_100: string,
  }
  communityManager?: {
    id: string;
    firstName: string,
    lastName: string,
  }
  isCommunityManager?: boolean;
  guest?: string;
  onGuestMonth?: boolean;
  mentor?: {
    id: string;
    firstName: string,
    lastName: string,
  }
  isMentor?: boolean;
}

export interface ResidentCreate {
  id: number,
  firstName: string,
  lastName: string,
  password: string,

  roles: UserRoles,
  positionTags: string[],
  department: string,

  avatar: File[] | string | null,
  birthday: Date,
  sex: boolean | '',

  phone: string,
  email: string,
  facebook: string,
  instagram: string,
  telegram: string,

  chapter?: string,

  dateOfEntry: Date,

  companies: CompanyData[],
  companiesData: Company[],

  status: UserStatus,

  guest?: string,
  onGuestMonth?: boolean | null,

  // Additions
  businessStartYear: Date,
  atlas: number,
  badge: string,
  turnoverPerYear: number,
  numberOfEmployees: number,
  education: string,
  interests: string[],
  personalWww: string,
  videoPresentation: string,
  form_group: string,
  city: string[],
  yearTarget: string,
  factsAboutMe: string,
  expertise: string,
  provideResources: string,
  achievements: string,
  lookResources: string,
  request: string,
  industries: string[],
  familyStatus: string,
  children: string,
}
