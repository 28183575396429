import { tablePageController, translate } from 'magner';
import bansTable from 'features/settings/bans/table/hubstr';
import { bansHead } from 'features/settings/bans/requests/hubstr';

const bansConfig: any = tablePageController<any>({
  header: {
    title: translate('hubstr.bans.table.title'),
    actions: [
      {
        type: 'link',
        to: { name: 'complaints' },
        props: {
          type: 'primary',
          text: translate('hubstr.bans.filters.complaints'),
        },

      },
      {
        type: 'link',
        to: { name: 'bans' },
        props: {
          type: 'primary',
          text: 'Блокировки',
        },

      },
    ],
  },
  request: bansHead,
  table: bansTable as any,
  filters: {
    fieldsShowAmount: 5,
    filtersData: {},
    actions: [],
    layout: [],
    pagination: {
      items: 25,
      page: 1,
    },
  },
});

export default bansConfig;
