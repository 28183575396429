import {request} from "~/utils/request";
import {ITypeTag} from "features/settings/tags/types/ITypeTag";

export const getTags = request.card<ITypeTag>(async ({ api }) => {
  const res = await api.get<{ tags: Tags[] }>('/api/admin/post/tag/list-short?count=1000');
  if (res.error) {
    return {
      data: {
        rows: [],
        pagination: null,
      },
    };
  }

  return {
    data: {
      rows: res.data?.list,
      pagination: null,
    },
  };
});