<template>
  <div style="width: 100%;">
    <el-collapse accordion>
      <el-collapse-item v-for="(question, index) in state.questions" :key="question.id" :name="index">
        <template #title>
          <div class="faq-question-container">
            <div class="faq-question-container__part">
              <p class="faq-question-container__question">{{ question.name }}</p>
            </div>
            <div class="faq-question-container__part faq-question-container__part_nowrap">
              <el-button
                style="width: auto;margin: 0 0 0 8px"
                type="primary"
                link
                @click.stop="initEditQuestion(question)"
              >
                {{ translateText('gaz.faq.form.edit_button') }}
              </el-button>

              <el-button
                style="width: auto;margin: 0 0 0 8px"
                type="danger"
                link
                @click.stop="deleteQuestion(question)"
              >
                {{ translateText('gaz.faq.form.delete_button') }}
              </el-button>
            </div>
          </div>
        </template>
        <div class="faq-answer-wrapper">
          <div class="faq-answer-container">
            <h4>Ответ:</h4>
            <p>{{ question.text }}</p>
          </div>
          <div class="faq-answer-container">
            <h4>Прикрепленные файлы:</h4>
            <el-table v-if="question.files.length" :data="question.files" style="width: 100%">
              <el-table-column prop="name" :label="translateText('gaz.faq.form.name')" />
              <el-table-column prop="actions" :label="translateText('gaz.faq.form.actions')" width="60">
                <template #default="{row}">
                  <el-button
                    style="margin: 0;"
                    type="danger"
                    link
                    @click.stop="deleteFile(question, row)"
                  >
                    {{ translateText('gaz.faq.form.delete_button') }}
                  </el-button>
                </template>
              </el-table-column>
            </el-table>

            <el-button
              style="margin: 20px 0 0 0;width: auto;"
              :icon="Plus"
              type="primary"
              link
              @click="initAddFile(question)"
            >
              {{ translateText('gaz.faq.form.add_file_button') }}
            </el-button>
          </div>
        </div>
      </el-collapse-item>
    </el-collapse>

    <div style="margin: 20px 0 0 0;max-width: 200px">
      <el-button :icon="Plus" plain @click="initAddQuestion">
        {{ translateText('gaz.faq.form.add_question_button') }}
      </el-button>
    </div>
  </div>

  <el-dialog
    v-if="state.questionModal.visible"
    v-model="state.questionModal.visible"
    :title="translateText('gaz.faq.modal_text.title')"
  >
    <el-input
      v-model="state.questionModal.name"
      :placeholder="translateText('gaz.faq.modal_text.name_placeholder')"
    />

    <el-input
      v-model="state.questionModal.text"
      style="margin-top: 20px"
      :rows="5"
      type="textarea"
      :placeholder="translateText('gaz.faq.modal_text.text_placeholder')"
    />

    <template #footer>
      <span class="dialog-footer">
        <el-button style="width: auto;margin-right: 10px;" @click="state.questionModal.visible = false">
          {{ translateText('gaz.faq.modal_text.cancel') }}
        </el-button>
        <el-button
          :disabled="state.questionModal.name.length === 0 || state.questionModal.text.length === 0"
          style="width: auto;"
          type="primary"
          @click="saveQuestion"
        >
          {{ translateText('gaz.faq.modal_text.ok') }}
        </el-button>
      </span>
    </template>
  </el-dialog>

  <el-dialog
    v-if="state.fileModal.visible"
    v-model="state.fileModal.visible"
    :title="translateText('gaz.faq.modal_file.title')"
  >
    <el-input v-model="state.fileModal.name" :placeholder="translateText('gaz.faq.modal_file.name')" />

    <div style="margin-top: 20px;">
      <magner-form-dropzone v-model="state.fileModal.image" :field="dropZoneField" />
    </div>

    <template #footer>
      <span class="dialog-footer">
        <el-button style="width: auto;margin-right: 10px;" @click="state.fileModal.visible = false">
          {{ translateText('gaz.faq.modal_file.cancel') }}
        </el-button>
        <el-button
          :disabled="state.fileModal.name.length === 0"
          style="width: auto;"
          type="primary"
          @click="saveFile"
        >
          {{ translateText('gaz.faq.modal_file.ok') }}
        </el-button>
      </span>
    </template>
  </el-dialog>
</template>

<script lang="ts" setup>
import {
  defineEmits, defineProps, onMounted, PropType, reactive,
} from 'vue';
import {
  magnerConfirm, MagnerFormDropzone, translate, useTranslate,
} from 'magner';
import { v4 as uuidv4 } from 'uuid';
import {
  Plus,
} from '@element-plus/icons-vue';
import { fileUpload } from 'features/common/file-request';

interface FaqQuestion {
  id: string;
  name: string;
  text: string;
  files: {
    name: string;
    link: string;
  }[]
}

const dropZoneField = {
  type: 'dropzone',
  name: 'image',
  dataType: 'number',
  label: translate('gaz.faq.form.image.label'),
  props: {
    valueKey: 'id',
    srcKey: 'image',
    removable: true,
  },
};

const emits = defineEmits(['update:modelValue']);
const props = defineProps({
  modelValue: {
    type: Object as PropType<any>,
    required: true,
  },
});

const { customT } = useTranslate();
const translateText = (code: string) => customT(translate(code));

const state: {
  id: string;
  code: string;
  title: string;
  questions: FaqQuestion[];

  questionModal: {
    visible: boolean;
    id: string;
    name: string;
    text: string;
    isEdit: boolean;
  };
  fileModal: {
    visible: boolean;
    id: string;
    name: string;
    image: any;
  }
} = reactive({
  id: '',
  code: '',
  title: '',
  questions: [],

  questionModal: {
    visible: false,
    id: '',
    name: '',
    text: '',
    isEdit: false,
  },
  fileModal: {
    visible: false,
    id: '',
    name: '',
    image: null,
  },
});
/**
 *
 */
const parseJson = (list: {type: string, name: string}[]) => {
  state.questions = [];
  list.map((item) => {
    state.questions.push({
      id: uuidv4(),
      name: item.name,
      text: (item as any).widgets[0].text,
      files: (item as any).widgets[2]?.widgets.map((w: any) => ({
        ...w,
        id: uuidv4(),
      })) ?? [],
    });
  });
};
/**
 *
 */
const convertToJson = () => state.questions.map((q: FaqQuestion) => {
  const widgets: any = [{
    type: 'text_widget',
    color: '#B31E1A34',
    fontSize: 16,
    fontWeight: 400,
    text: q.text,
  }];

  if (q.files && q.files.length > 0) {
    widgets.push({
      type: 'vertical_gap_widget',
      size: 15.0,
    });
    widgets.push({
      type: 'separated_group_widget',
      widgets: q.files.map((f) => ({
        name: f.name,
        link: f.link,
        type: 'small_rounded_button_widget',
      })),
    });
  }

  return {
    type: 'expansion_panel_item_widget',
    name: q.name,
    widgets,
  };
});
/**
 * Метод формирует JSON-массив и отдает его наверх
 */
const emitData = () => {
  const out = {
    id: state.id,
    code: state.code,
    title: state.title,
    widgets: {
      header: [],
      body: [
        {
          type: 'expansion_panel_group_widget',
          widgets: convertToJson(),
        },
      ],
      footer: [],
    },
  };
  emits('update:modelValue', out);
};
/**
 *
 */
const initAddQuestion = () => {
  state.questionModal.id = uuidv4();
  state.questionModal.name = '';
  state.questionModal.text = '';
  state.questionModal.isEdit = false;
  state.questionModal.visible = true;
};
/**
 *
 */
const initEditQuestion = (data: any) => {
  state.questionModal.id = data.id;
  state.questionModal.name = data.name;
  state.questionModal.text = data.text;
  state.questionModal.isEdit = true;
  state.questionModal.visible = true;
};
/**
 *
 */
const saveQuestion = () => {
  if (state.questionModal.isEdit) {
    state.questions = [...state.questions.map((q: FaqQuestion) => {
      if (q.id === state.questionModal.id) {
        return {
          ...q,
          name: state.questionModal.name,
          text: state.questionModal.text,
        };
      }

      return q;
    })];
  } else {
    state.questions.push({
      id: state.questionModal.id,
      name: state.questionModal.name,
      text: state.questionModal.text,
      files: [],
    });
  }

  state.questionModal.visible = false;
  emitData();
};
/**
 *
 */
const deleteQuestion = (data: any) => {
  magnerConfirm({
    message: customT(translate('gaz.faq.delete_question.message')),
    title: customT(translate('gaz.faq.delete_question.title')),
    confirmButtonText: customT(translate('gaz.faq.delete_question.confirm')),
    cancelButtonText: customT(translate('gaz.faq.delete_question.cancel')),
    type: 'warning',
  }).then(() => {
    state.questions = [...state.questions.filter((q: FaqQuestion) => q.id !== data.id)];
    emitData();
  });
};
/**
 *
 */
const initAddFile = (data: any) => {
  state.fileModal.id = data.id;
  state.fileModal.name = '';
  state.fileModal.image = null;
  state.fileModal.visible = true;
};
/**
 *
 */
const uploadFile = async (data: any, id: string) => {
  const fileUploadRes = await fileUpload(
    id as string,
    (data as File[])[0],
    'view',
    'view_file',
    'default',
    true,
  );

  if (fileUploadRes.error) {
    return false;
  }

  return fileUploadRes.data;
};
const saveFile = async () => {
  const resImage = await uploadFile(state.fileModal.image, state.fileModal.id);
  state.questions = [...state.questions.map((q: FaqQuestion) => {
    if (q.id === state.fileModal.id) {
      q.files.push({
        name: state.fileModal.name,
        link: resImage.image,
      });
    }

    return q;
  })];
  state.fileModal.visible = false;
  emitData();
};
/**
 *
 */
const deleteFile = (question: {id: string}, row: {id: string}) => {
  magnerConfirm({
    message: customT(translate('gaz.faq.delete_file.message')),
    title: customT(translate('gaz.faq.delete_file.title')),
    confirmButtonText: customT(translate('gaz.faq.delete_file.confirm')),
    cancelButtonText: customT(translate('gaz.faq.delete_file.cancel')),
    type: 'warning',
  }).then(() => {
    state.questions = [...state.questions.map((q: FaqQuestion) => {
      if (q.id === question.id) {
        return {
          ...q,
          files: q.files.filter((f: any) => f.id !== row.id),
        };
      }

      return q;
    })];

    emitData();
  });
};
/**
 *
 */
onMounted(() => {
  state.id = props.modelValue.id;
  state.code = props.modelValue.code;
  state.title = props.modelValue.title;
  parseJson(props.modelValue.widgets.body[0].widgets);
});
</script>

<style lang="scss">
.faq-question-container {
  display: flex;
  justify-content: space-between;
  width: 100%;
  padding: 5px 15px 5px 0;
  box-sizing: border-box;

  &__part {
    display: inline-flex;
    align-items: center;

    &_nowrap {
      white-space: nowrap;
    }
  }

  &__question {
    margin: 0;
    padding: 0;
    line-height: 1.2;
  }
}

.faq-answer-wrapper {
  display: block;
  width: 100%;
  box-sizing: border-box;
  padding: 5px 0 5px 15px;
  border-left: 3px solid var(--el-border-color);
}

.faq-answer-container {
}
</style>
