import {
  Category, Event, EventCreate, EventReceive, OrganizerResident,
} from 'features/events/types/hubstr';
import { v4 as uuidv4 } from 'uuid';
import { fileUpload } from 'features/common/file-request';
import { residentSearch } from 'features/residents/requests/hubstr';
import { request } from '~/utils/request';

export const eventGet = request.card<EventReceive, EventCreate>(async ({ api, data, parseError }) => {
  const res = await api.get<{ event: Event }>(`/api/admin/event?event=${data.id}`);
  if (res.error) {
    return { error: parseError(res.error) };
  }

  const page = document.querySelector('.page');
  if (res.data?.event.dateEnd && new Date() > new Date(res.data?.event.dateEnd)) {
    page?.classList.add('event-past');
  } else {
    page?.classList.remove('event-past');
  }

  return {
    data: {
      ...res.data?.event,
      categories: res.data?.event.categories.map((category: Category) => category.id),
      owner: res.data?.event.owner ? res.data.event.owner.id : null,
      address: res.data?.event.address ? res.data.event.address.id : null,
      dateStart: [new Date(res.data?.event.dateStart), new Date(res.data?.event.dateEnd)] as unknown as Date,
      dateEnd: new Date(res.data?.event.dateEnd),
      invitedDepartments: Object.values(res.data?.event.invitedDepartments || {}).map(([id]) => id),
      materialsLink: res.data?.event.additions?.materialsLink,
      type: res.data?.event.type === 'main' ? ['main'] : [],
      publish: res.data?.event.id,
      isPublished: res.data?.event.isPublished,
    },
  };
});

export const eventCreate = request.card<Event, EventCreate>(async ({
  api, parseError, data, router,
}) => {
  const date = data.data.dateStart as unknown as [Date, Date];
  const id = uuidv4();

  const body = {
    ...data.data,
    dateStart: date?.[0],
    dateEnd: date?.[1],
    id,
  };

  if (data.data.logo) {
    const fileUploadRes = await fileUpload(
      id as string,
      (data.data.logo as File[])[0],
      'event',
      'event_logo',
      'eventLogo',
    );

    if (fileUploadRes.error) {
      return {
        error: {
          message: '',
          fields: { logo: fileUploadRes.error as unknown as string },
        },
      };
    }
    body.logo = fileUploadRes.data?.id;
  }

  if (data.data.categories?.length) {
    body.categories = data.data.categories
      .map((category) => (typeof category === 'object' ? (category as any)?.id : category));
  }
  if (typeof data.data.address === 'object') body.address = (body.address as any)?.id || body.address;

  if (data.data.organizers?.length) {
    const owner = data.data.organizers.find((org) => org.type === 'resident' && org.id) as OrganizerResident;
    body.owner = '';
    if (owner) {
      body.owner = owner.id;
    }
  }

  body.type = data.data.type && data.data.type.length ? data.data.type[0] as 'main' : 'additional';

  const res = await api.post<{ event: Event }>('/api/admin/event', body);
  if (res.error) {
    return { error: parseError?.(res.error) };
  }

  router.push({ name: 'event', params: { id: res.data?.event.id } });
  return { data: res.data?.event };
});

export const eventUpdate = request.card<Event, EventCreate>(async ({ api, parseError, data }) => {
  const body = { ...data.data, id: data.id };
  if (data.data.logo) {
    const fileUploadRes = await fileUpload(
      data.id as string,
      (data.data.logo as File[])[0],
      'event',
      'event_logo',
      'eventLogo',
    );

    if (fileUploadRes.error) {
      return {
        error: {
          message: '',
          fields: { logo: fileUploadRes.error as unknown as string },
        },
      };
    }
    body.logo = fileUploadRes.data?.id;
  }

  if (data.data.dateStart) {
    const date = data.data.dateStart as unknown as [Date, Date];
    body.dateStart = date?.[0];
    body.dateEnd = date?.[1];
  }

  if (data.data.categories?.length) {
    body.categories = data.data.categories
      .map((category) => (typeof category === 'object' ? (category as any)?.id : category));
  }

  if (data.data.organizers?.length) {
    const owner = data.data.organizers.find((org) => org.type === 'resident' && org.id) as OrganizerResident;
    body.owner = '';
    if (owner) {
      body.owner = owner.id;
    }
  }

  if (data.data.materialsLink?.length) {
    body.additions = {
      materialsLink: data.data.materialsLink,
    };
  }

  if (data.data.type) {
    body.type = data.data.type.length ? data.data.type[0] as 'main' : 'additional';
  }

  const res = await api.patch<{ event: Event }>('/api/admin/event', body);
  if (res.error) {
    return { error: parseError?.(res.error) };
  }
  return { data: res.data?.event };
});
