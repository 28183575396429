<template>
  <el-row>
    <h2>Выгрузка участников мероприятия</h2>
  </el-row>

  <el-row gutter="30">
    <el-col>
      <el-select
        v-model="state.selectedFormat"
        :placeholder="'Выбрать формат'"
        :loading="state.loading"
        :loading-text="'Загрузка'"
        :filterable="true"
        :remote="true"
        style="width: 100%"
      >
        <el-option
          v-for="option in state.Format"
          :key="option.id"
          :value="option.id"
          :label="option.name"
        />
      </el-select>
    </el-col>
  </el-row>

  <el-row gutter="30">
    <el-col>
      <el-button native-type="button" type="primary" @click="submitFormat">
        {{ "Сохранить" }}
      </el-button>
    </el-col>
  </el-row>
</template>

<script lang="ts">
import { defineComponent, reactive } from 'vue';
import { exportFile } from '../requests/hubstr';

export default defineComponent({
  name: 'ExportForm',
  emits: ['success'],
  setup(props, { emit }) {
    const state: {
      selectedFormat: string[] | null;
      loading: boolean;
      Format: { id: number; name: string }[];
    } = reactive({
      selectedFormat: null,
      loading: false,
      Format: [
        { id: 0, name: 'xlsx' },
        { id: 1, name: 'csv' },
      ],
    });

    const submitFormat = async () => {
      await exportFile({
        format: state.selectedFormat,
      });

      emit('success');
    };

    return {
      state,

      submitFormat,
    };
  },
});
</script>
