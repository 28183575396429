<template>
  <el-row style="padding: 30px 0 0 0">
    <el-col :span="12">
      <p style="display: inline-block;padding-right: 10px;margin: 0;">{{ `${translateText('gaz.interview.list_question.title')}:` }}</p>
      <el-radio-group v-model="state.dataType" @update:modelValue="emitData">
        <el-radio
          v-for="option in DATA_TYPE_OPTIONS"
          :key="option.value"
          :label="option.value"
          :disabled="onlyMeta"
          border
        >
          {{ translateText(option.label) }}
        </el-radio>
      </el-radio-group>
    </el-col>
    <el-col :span="12">
      <el-checkbox
        v-model="state.selfAnswer"
        :label="translateText('gaz.interview.list_question.self_answer')"
        border
        :disabled="onlyMeta"
        @update:modelValue="emitData"
      />
    </el-col>
  </el-row>
  <el-row style="padding: 20px 0 0 0">
    <el-col :span="12">
      <template v-if="state.dataType === DATA_TYPE.STRING">
        <el-checkbox
          v-model="state.multiselect"
          :label="translateText('gaz.interview.list_question.multi_select')"
          border
          :disabled="onlyMeta"
          @update:modelValue="emitData"
        />
      </template>
      <template v-else>
        <p style="display: inline-block;margin: 0;padding-right: 10px;">{{ `${translateText('gaz.interview.list_question.total_limit')}:` }}</p>
        <el-input-number
          v-model="state.totalLimit"
          :min="state.totalLimitMin"
          @update:modelValue="emitData"
        />
      </template>
    </el-col>
    <el-col :span="12">
      <el-checkbox
        v-model="state.showNewScreen"
        :label="translateText('gaz.interview.list_question.show_new_screen')"
        border
        :disabled="onlyMeta"
        @update:modelValue="emitData"
      />
    </el-col>
  </el-row>

  <div style="display: block;padding: 40px 0">
    <template v-for="(item, index) in state.options" :key="item.uuid">
      <el-row style="margin-bottom: 20px;" justify="end">
        <el-col :span="18">
          <template v-if="state.dataType === DATA_TYPE.STRING">
            <el-input
              v-model="item.value"
              :placeholder="translateText('gaz.interview.list_question.option_placeholder')"
              :class="[{'input_error': errors.options[item.uuid] && showErrors}]"
              :disabled="onlyMeta"
              @update:modelValue="emitData"
            />
          </template>
          <template v-else>
            <el-input-number
              v-model="item.value"
              :class="[{'input_error': errors.options[item.uuid] && showErrors}]"
              :disabled="onlyMeta"
              @update:modelValue="emitData"
            />
          </template>
        </el-col>
        <el-col :span="6" style="text-align: right">
          <el-button-group v-if="!onlyMeta">
            <el-button
              style="width: auto;margin-bottom: 0;"
              :disabled="index === 0"
              type="primary"
              :icon="ArrowUpBold"
              plain
              @click="moveOptionUp(item.uuid)"
            />
            <el-button
              style="width: auto;margin-bottom: 0;"
              :disabled="index === (state.options.length - 1)"
              type="primary"
              :icon="ArrowDownBold"
              plain
              @click="moveOptionDown(item.uuid)"
            />
            <el-button
              style="width: auto;margin-bottom: 0;"
              type="danger"
              :icon="Delete"
              plain
              @click="deleteOption(item.uuid)"
            />
          </el-button-group>
        </el-col>
      </el-row>
    </template>

    <el-row v-if="!onlyMeta" style="padding: 10px 0 0 0">
      <el-col style="border-bottom: 1px solid #dcdfe6">
        <el-button style="width: auto" round @click="addOption">
          + {{ translateText('gaz.interview.list_question.add_button') }}
        </el-button>
      </el-col>
    </el-row>
  </div>
</template>

<script lang="ts">
import {
  ArrowUpBold,
  ArrowDownBold,
  Delete,
} from '@element-plus/icons-vue';
import {
  defineComponent, onMounted, PropType, reactive, watch,
} from 'vue';
import { v4 as uuidv4 } from 'uuid';
import {
  magnerConfirm, translate, useTranslate,
} from 'magner';
import { IListQuestion, IListQuestionOption } from 'features/settings/interviews/interfaces';
import { DATA_TYPE_OPTIONS, DATA_TYPE } from 'features/settings/interviews/constants';

interface IListQuestionState {
  dataType: string;
  multiselect: boolean;
  selfAnswer: boolean;
  showNewScreen: boolean;
  options: IListQuestionOption[];
  totalLimit: number;
  totalLimitMin: number;
}

export default defineComponent({
  name: 'ListQuestion',
  props: {
    data: {
      type: Object as PropType<IListQuestion>,
      required: true,
    },
    showErrors: Boolean,
    onlyMeta: Boolean,
  },
  emits: ['update:data'],
  setup (props, { emit }) {
    const { customT } = useTranslate();

    const state: IListQuestionState = reactive({
      dataType: props.data.dataType ?? DATA_TYPE_OPTIONS[0].value,
      multiselect: props.data.multiselect ?? false,
      selfAnswer: props.data.selfAnswer ?? false,
      showNewScreen: props.data.showNewScreen ?? false,
      options: props.data.options ?? [],
      totalLimit: props.data.totalLimit ?? 0,
      totalLimitMin: props.onlyMeta && props.data.totalLimit ? props.data.totalLimit : 0,
    });
    const errors: { options: Record<string, boolean> } = {
      options: {},
    };
    /**
     *
     */
    watch(() => props.data, () => {
      state.dataType = props.data.dataType ?? DATA_TYPE_OPTIONS[0].value;
      state.multiselect = props.data.multiselect ?? false;
      state.selfAnswer = props.data.selfAnswer ?? false;
      state.showNewScreen = props.data.showNewScreen ?? false;
      state.options = props.data.options ?? [];
      state.totalLimit = props.data.totalLimit ?? 0;
    });
    /**
     * валидация введенных данных
     */
    const validateData = () => {
      errors.options = {};
      state.options.map((o: IListQuestionOption) => {
        errors.options[o.uuid] = o.value === '';
        return o;
      });
    };
    /**
     * Метод отправляет изменения "наверх"
     */
    const emitData = () => {
      validateData();

      const out: Partial<IListQuestionState> = {
        dataType: state.dataType,
        multiselect: state.multiselect,
        selfAnswer: state.selfAnswer,
        showNewScreen: state.showNewScreen,
        options: state.options,
      };

      if (state.totalLimit > 0) {
        out.totalLimit = state.totalLimit;
      }

      emit('update:data', {
        data: out,
        errors: Object.values(errors.options).length ? Object.values(errors.options).reduce((p, c) => p || c) : false,
      });
    };
    /**
     * Метод добавляет новый вариант ответа в список
     */
    const addOption = () => {
      state.options.push({
        uuid: uuidv4(),
        value: '',
        serial: state.options.length + 1,
      });

      emitData();
    };
    /**
     * Метод удаляет выбранный вариант ответа из списка
     * @param uuid
     */
    const deleteOption = (uuid: string) => {
      magnerConfirm({
        message: customT(translate('gaz.interview.list_question.confirm_delete.message')),
        title: customT(translate('gaz.interview.list_question.confirm_delete.title')),
        confirmButtonText: customT(translate('gaz.interview.list_question.confirm_delete.confirm')),
        cancelButtonText: customT(translate('gaz.interview.list_question.confirm_delete.cancel')),
        type: 'warning',
      }).then(() => {
        state.options = [...state.options.filter((item: IListQuestionOption) => item.uuid !== uuid)];
        emitData();
      });
    };
    /**
     * Метод сортирует варианты ответов после смены последовательности
     */
    const sortAnswers = () => {
      state.options.sort((a: IListQuestionOption, b: IListQuestionOption) => a.serial - b.serial);
    };
    /**
     * Метод перемещает выбранный вариант ответа вверх на одну позицию в списке
     * @param uuid
     */
    const moveOptionUp = (uuid: string) => {
      state.options.reduce((prev: IListQuestionOption, current: IListQuestionOption) => {
        if (current.uuid === uuid) {
          const tmpSerial = prev.serial;
          prev.serial = current.serial;
          current.serial = tmpSerial;
        }
        return current;
      });

      sortAnswers();
    };
    /**
     * Метод перемещает выбранный вариант ответа вниз на одну позицию в списке
     * @param uuid
     */
    const moveOptionDown = (uuid: string) => {
      state.options.reduce((prev: IListQuestionOption, current: IListQuestionOption) => {
        if (prev.uuid === uuid) {
          const tmpSerial = prev.serial;
          prev.serial = current.serial;
          current.serial = tmpSerial;
        }
        return current;
      });

      sortAnswers();
    };

    onMounted(() => {
      setTimeout(() => {
        emitData();
      });
    });

    return {
      state,
      errors,

      emitData,
      addOption,
      deleteOption,
      moveOptionUp,
      moveOptionDown,
      translateText: (code: string) => customT(translate(code)),

      ArrowUpBold,
      ArrowDownBold,
      Delete,

      DATA_TYPE_OPTIONS,
      DATA_TYPE,
    };
  },
});
</script>
