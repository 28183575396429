import { filtersFormController, translate } from 'magner';
import { Resident } from 'features/residents/types/hubstr';
import { buddyPartnersListGet } from 'features/settings/buddy-partners/requests/hubstr';

const buddyPartnersFilters = filtersFormController<Resident>({
  actions: [],

  saveToLocalStorage: true,

  submitEvent: 'input',

  pagination: {
    items: 25,
    page: 1,
  },

  filtersData: {
    mentor: '',
    firstName: '',
  },
  sort: {},

  layout: [{
    type: 'select',
    name: 'mentor',
    dataType: 'array',
    options: [],
    props: {
      multiple: false,
      filterable: true,
      remote: true,
      collapseTags: true,
      valueKey: 'id',
      labelKey: 'fio',
      remoteMethod: buddyPartnersListGet,
      placeholder: translate('atlanty.buddy_partner.filters.buddy_partner'),
    },
  }, {
    type: 'input',
    name: 'firstName',
    props: {
      placeholder: translate('atlanty.buddy_partner.filters.name'),
      inputDelay: 250,
    },
  }],
});

export default buddyPartnersFilters;
