import { cardPageController, translate } from 'magner';
import {
  partnerCreate, partnerGet, partnerDelete, partnerUpdate,
} from 'features/settings/partners/requests/hubstr';
import type { PartnerFull } from 'features/settings/partners/types/hubstr';

const partnerConfig = cardPageController<PartnerFull>({
  getRequest: partnerGet,
  createRequest: partnerCreate,
  updateRequest: partnerUpdate,
  deleteRequest: partnerDelete,

  header: {
    title: translate('hubstr.partner.form.title_create'),
  },

  form: {
    actions: [
      {
        type: 'action',
        emits: 'submit',
        props: {
          type: 'primary',
          text: translate('hubstr.partner.form.submit_button'),
        },
      },
      {
        type: 'action',
        emits: 'remove',
        props: {
          type: 'danger',
          text: translate('hubstr.partner.form.remove_button'),
        },
      },
    ],
    layout: {
      type: 'column',
      props: {},
      fields: [
        {
          type: 'input',
          name: 'title',
          label: translate('hubstr.partner.form.name.label'),
          props: {
            required: true,
            placeholder: translate('hubstr.partner.form.name.placeholder'),
          },
          validation: [{
            type: 'empty',
            trigger: 'blur',
          }],
        },

        {
          type: 'input',
          name: 'url',
          label: translate('hubstr.partner.form.url.label'),
          props: {
            required: true,
            placeholder: translate('hubstr.partner.form.url.placeholder'),
          },
          validation: [{
            type: 'empty',
            trigger: 'blur',
          }, {
            type: 'url' as 'empty',
            trigger: 'blur',
          }],
        },

        {
          type: 'textarea',
          name: 'description',
          label: translate('hubstr.partner.form.description.label'),
          props: {
            placeholder: translate('hubstr.partner.form.description.placeholder'),
            maxLength: 500,
            showLetterLimit: true,
            rows: 5,
          },
        },

        {
          type: 'dropzone',
          name: 'image',
          dataType: 'number',
          label: translate('hubstr.partner.form.image.label'),
          props: {
            valueKey: 'id',
            srcKey: 'image',
            removable: true,
          },
        },
      ],
    },
  },
});

export default partnerConfig;
