import { request } from '~/utils/request';
import { ChapterList } from '../types/hubstr';

export const chaptersRead = request.table<ChapterList[]>(async ({ api }) => {
  const res = await api.get<any>('/api/admin/chapter');
  if (res.error) {
    return {
      data: { rows: [] },
    };
  }

  return {
    data: {
      rows: res.data.list.sort((a: any, b: any) => a.weight - b.weight),
    },
  };
});

export const createChapter = request.custom<any>(async ({ api, data }) => {
  const body = { ...data };

  const res = await api.post<any>('/api/admin/chapter', body);

  if (res.error) {
    return {
      data: { rows: [] },
    };
  }

  return {
    data: {
      rows: res.data.list,
    },
  };
});

export const updateChapter = request.custom<any>(async ({ api, data }) => {
  const body = { name: data.name, weight: data.weight };

  const res = await api.put<any>(`/api/admin/chapter/${data.chapterid}`, body);

  if (res.error) {
    return {
      data: { rows: [] },
    };
  }

  return {
    data: {
      rows: res.data.list,
    },
  };
});
