<template>
  <el-button
    v-if="row.mentor && row.mentor.id"
    @click="clickButton"
  >
    {{ `${row.mentor.firstName} ${row.mentor.lastName}` }}
  </el-button>
</template>

<script lang="ts">
import {
  defineComponent, PropType,
} from 'vue';

export default defineComponent({
  name: 'BuddyPartnerLink',
  props: {
    row: {
      type: Object as PropType<any>,
      required: true,
    },
    column: {
      type: Object as PropType<any>,
      required: true,
    },
  },
  setup (props) {
    // @ts-ignore
    const clickButton = () => {
      const lstorage = JSON.parse(localStorage.getItem('magner-hubstr') || '');
      lstorage.filters['buddy-partners'].filters.mentor = props.row.mentor.id;
      localStorage.setItem('magner-hubstr', JSON.stringify(lstorage));

      window.location.href = '/buddy-partners';
    };

    return {
      clickButton,
    };
  },
});
</script>
