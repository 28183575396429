import {
  DATA_TYPE, DATE_DATA_TYPE, QUESTION_TYPE, TEXT_LENGTH,
} from 'features/settings/interviews/constants';
/**
 * интерфейсы для условий отображения вопросов и шагов
 */
export const CONDITION_OPERATORS = {
  EQUALS: 'eq',
  GREATER: 'gt',
  LESS: 'ls',
  EMPTY: 'empty',
  NOT_EMPTY: 'not_empty',
  OPTION: 'option',
};
/**
 * Условие отображения вопроса или шага
 */
export interface ICondition {
  id: string;
  serial: number;
  parentQuestionUuid: string;
  parentQuestion?: any;
  operator: typeof CONDITION_OPERATORS[keyof typeof CONDITION_OPERATORS];
  value: string;
}
/**
 * Блок условий. Блоки конкатенируются через "ИЛИ"
 */
export interface IConditionBlock {
  id: string;
  serial: number;
  conditions: ICondition[];
}
/**
 * Вопрос (общие поля, от этого интерфейса наследуются остальные)
 */
export interface IQuestion {
  // уникальный идентификатор вопроса
  uuid: string;
  // порядковый номер вопроса в опроснике
  serial: number;
  // порядковый номер вопроса в шаге
  stepSerial: number;
  // тип вопроса
  type: typeof QUESTION_TYPE[keyof typeof QUESTION_TYPE],
  // текст вопроса
  question: string;
  // описание вопроса
  description: string;
  // обязательный вопрос или нет
  required: boolean;

  // "свернут" вопрос или нет
  collapsed: boolean;

  // условия показа вопроса
  conditions?: IConditionBlock[] | ICondition[][];
}
/**
 * Оценка (заранее заданный максимальный бал)
 */
export interface IStarsQuestion extends IQuestion {
  // максимальное значение оценки
  intLimit: number;
}
/**
 * Простой вопрос (Текстовое поле)
 */
export interface IPlainAnswerQuestion extends IQuestion {
  // тип данных в ответе
  dataType: typeof DATA_TYPE[keyof typeof DATA_TYPE];
  // длина текста для варианта dataType=type_string
  textLength?: typeof TEXT_LENGTH[keyof typeof TEXT_LENGTH];
  // ограничение максимального значения dataType=type_int
  intLimit?: number;
  // глобальный лимит мероприятия
  totalLimit?: number;
}
/**
 * Список
 */
export interface IListQuestionOption {
  uuid: string;
  value: string;
  serial: number;
}
/**
 * Тип вопроса - Список
 */
export interface IListQuestion extends IQuestion {
  // тип данных в ответе
  dataType: typeof DATA_TYPE[keyof typeof DATA_TYPE];
  // разрешен ли выбор нескольких вариантов
  multiselect: boolean;
  // можно ли дать свой ответ
  selfAnswer: boolean;
  // Открывать список в новом экране МП
  showNewScreen: boolean;
  // глобальный лимит
  totalLimit?: number;
  // массив вариантов ответа
  options: IListQuestionOption[]
}
/**
 * Тип вопроса Дата/Время
 */
export interface IDateQuestion extends IQuestion {
  dataType: typeof DATE_DATA_TYPE[keyof typeof DATE_DATA_TYPE]
}
/**
 * Тип вопроса - загрузка фойла
 */
export interface IFileQuestion extends IQuestion {
  maxSize: number;
  photo: boolean;
  document: boolean;
  audio: boolean;
  video: boolean;
}
/**
 * Тип данных с перечислением всех типов вопросов
 */
export type IQuestionType = IStarsQuestion | IPlainAnswerQuestion | IListQuestion | IDateQuestion;
/**
 * Шаг опросника
 */
export interface IStep {
  uuid: string;
  serial: number;
  collapsed: boolean;
  questions: IQuestionType[];
  stepCondition: IConditionBlock[] | ICondition[][];
  canHaveConditions?: boolean;
}
/**
 * Цена (дополнительная)
 */
export interface IPrice {
  id?: string;
  questionId: string;
  price: number;
  name: string;
}
/**
 * Общий интерфейс опросника
 */
export interface IInterview {
  id: string;
  name: string;
  description: string;
  basePrice?: number;
  totalLimit?: number;
  steps: IStep[];
  isPublished: boolean;
  prices: IPrice[];
  canDelete: boolean;
  createdAt?: string;

  onlyMeta?: boolean;
  showErrors?: boolean;
  gotErrors?: boolean;
}
