import { v4 as uuidv4 } from 'uuid';
import { fileUpload } from 'features/common/file-request';
import {
  Department, Resident, ResidentCreate, ResidentList, Tag, UserRoles, UserStatus,
} from 'features/residents/types/hubstr';
import { request } from '~/utils/request';

const BASE_URL = '/api/admin/user';

interface Pager {
  currentPage: number,
  totalPages: number,
  totalItems: number,
}

const createNestedKeys = ['badge', 'expertise', 'provideResources', 'achievements', 'yearTarget', 'lookResources',
  'request', 'industries', 'businessStartYear', 'turnoverPerYear', 'numberOfEmployees', 'factsAboutMe',
  'interests', 'chapter', 'education', 'familyStatus', 'children', 'videoPresentation', 'personalWww', 'form_group',
  'atlas', 'description', 'guest', 'onGuestMonth'];

export const residentRead = request.table<ResidentList>(async ({ api, data }) => {
  const pagination = `?count=${data.pagination.items || 25}&page=${data.pagination?.page || 1}`;
  // TODO: вернуть сортировку
  // const sort = '&sort[0][id]=lastName&sort[0][value]=ASC';
  const sort = '';
  const search = data.filters.firstName
    ? `&filters[0][id]=search&filters[0][value]=${data.filters.firstName}`
    : '';
  const phone = data.filters.phone
    ? `&filters[1][id]=phone&filters[1][value]=${data.filters.phone}`
    : '';
  const communityManager = data.filters.communityManager
    ? `&filters[2][id]=communityManager&filters[2][value]=${data.filters.communityManager}`
    : '';
  const buddyPartner = data.filters.mentor
    ? `&filters[2][id]=mentor&filters[2][value]=${data.filters.mentor}`
    : '';
  const positionTags = data.filters.positionTags
    ? `&filters[3][id]=positionTag&filters[3][value]=${data.filters.positionTags}`
    : '';

  let status = '';
  if (data.filters.status?.length) {
    const arr = data.filters.status.map((id: string) => `filters[4][value][]=${id}`) as string[];
    status = `&filters[4][id]=status&${arr.join('&')}`;
  }

  const communityManagerName = data.filters.communityManagerName
    ? `&filters[5][id]=communityManagerName&filters[5][value]=${data.filters.communityManagerName}`
    : '';

  const chapterSearch = data.filters.chapter
    ? `&filters[6][id]=chapter&filters[6][value]=${data.filters.chapter}`
    : '';
  const guest = data.filters.guest
    ? `&filters[7][id]=guest&filters[7][value]=${data.filters.guest}`
    : '';

  const res = await api.get<{ list: ResidentList[], pager: Pager }>(
    `${BASE_URL}/list${pagination}${sort}${search}${phone}${communityManager}${positionTags}${status}${buddyPartner}${communityManagerName}${chapterSearch}${guest}`,
  );
  if (res.error) {
    return {
      data: {
        rows: [],
        pagination: null,
      },
    };
  }

  return {
    data: {
      rows: res.data.list.map((item) => ({
        ...item,
        fio: `${item.firstName} ${item.lastName}`,
      })),
      pagination: {
        currentPage: res.data.pager?.currentPage || 1,
        totalPages: res.data.pager?.totalPages || 1,
        totalItems: res.data.pager?.totalItems || 0,
      },
    },
  };
});

export const residentSearch = request.custom<{ rows: Resident[] }, string>(async ({ api, data }) => {
  const search = data?.search ? `&filters[0][id]=search&filters[0][value]=${data.search}` : '';
  const res = await api.get<{ list: Resident[] }>(
    `${BASE_URL}/list?page=1&sortBy=firstName&sortWay=asc${search}`,
  );
  if (res.error) {
    return {
      data: { rows: [] },
    };
  }

  return {
    data: { rows: res.data.list.map((item) => ({ ...item, name: `${item.lastName} ${item.firstName}` })) },
  };
});

export const autocompleteSearch = (type: 'city'|'interests'|'industries'|'user_position'|'department'|'waste_types') => request
  .custom<{ rows: string[] }, string>(async ({ api, data }) => {
    const search = data?.search ? `&search=${data.search}` : '';
    const res = await api.get<{ result: string[] }>(`/api/form/autocomplete?field=${type}${search}`);
    if (res.error) {
      return {
        data: { rows: [] },
      };
    }

    return {
      data: { rows: res.data?.result },
    };
  });

export const autocompleteDepartment = request.custom<{ rows: Department[] }, string>(async ({ api, data }) => {
  const res = await api.get<{ departments: Department[] }>('/api/department/autocomplete');
  if (res.error) {
    return {
      data: { rows: [] },
    };
  }

  return {
    data: { rows: res.data?.departments },
  };
});

export const eventChapterSearch = request.custom<
  { rows: { id: string; name: string }[] },
  string
>(async ({ api }) => {
  const res = await api.get<{ list: { id: string; name: string }[] }>(
    '/api/admin/chapter',
  );
  if (res.error) {
    return {
      data: { rows: [] },
    };
  }

  return {
    data: { rows: res.data.list.sort((a: any, b: any) => a.weight - b.weight) },
  };
});

export const eventChapterFilter = request.custom<
  { rows: { id: string; name: string }[] },
  string
>(async ({ api }) => {
  const res = await api.get<{ list: { id: string; name: string }[] }>(
    '/api/admin/chapter',
  );
  if (res.error) {
    return {
      data: { rows: [] },
    };
  }

  return {
    data: {
      rows: [
        { id: 'no-assigned-chapters', name: 'Пусто' },
        ...res.data.list.sort((a: any, b: any) => a.weight - b.weight),
      ],
    },
  };
});

export const uploadVideo = request.custom< any, any >(async ({ api, data, router }) => {
  // const body = new FormData();
  // body.append('userId', router.currentRoute._value.params.id);
  // body.append('file', data);

  // const res = await api.post< any >(
  //   '/api/admin/user/upload-video-presentation', body, { headers: { 'Content-Type': 'multipart/form-data' } },
  // );

  const body = {
    userId: router.currentRoute._value.params.id,
    file: data,
  };

  const res = await api
    .instance
    .url('/api/admin/user/upload-video-presentation')
    .formData({
      ...body,
    })
    .post()
    .json((response) => (response.code === 200 ? { data: response.data } : { error: response.errors }))
    .catch(({ text }) => {
      const error = JSON.parse(text);

      return {
        error: error.result.errors,
      };
    });

  // if (res.error) {
  //   return {
  //     data: {},
  //   };
  // }

  if (!window.location.pathname.includes('residents/new')) {
    window.location.reload();
  }

  return {};
});

export const residentGet = request.card<Resident, ResidentCreate>(async ({ api, data, parseError }) => {
  const res = await api.get<{ user: Resident }>(`${BASE_URL}/read?id=${data.id}`);
  if (res.error) {
    return { error: parseError(res.error) };
  }

  return {
    data: {
      password: '',
      ...res.data?.user,
      roleCurrent: res.data?.user.roles?.[0],
      status: (res.data?.user.status as unknown as UserStatus).code,
      birthday: res.data?.user.birthday ? new Date(res.data?.user.birthday) as any : null,
      dateOfEntry: res.data?.user.dateOfEntry ? new Date(res.data?.user.dateOfEntry) as any : null,
      roles: res.data?.user?.roles?.[0] as any,
      ...res.data?.user.additions,
      positionTags: res.data?.user.positionTags?.map((tag) => (tag as unknown as Tag).id) as string[],
      city: res.data?.user.additions.city?.split(',').map((str) => str.trim())?.filter((str) => str) || null,
      interests: res.data?.user.additions.interests?.split(',').map((str) => str.trim())?.filter((str) => str) || null,
      industries: res.data?.user.additions.industries?.split(',').map((str) => str.trim())?.filter((str) => str) || null,
      recommendationUser: res.data?.user.recommendationUser?.id ?? null,
      description: res.data?.user.additions.description ?? '',
      videoPresentation: res.data?.user.additions.video_presentation ?? '',
    },
  };
});

// @ts-ignore
export const residentNew = request.card<Resident, ResidentCreate>(async ({ api, data, parseError }) => ({
  data: {
    roles: UserRoles.Resident,
  },
}));

export const residentUpdateStatus = request.card(async ({
  api, data, parseError,
}) => {
  const body = { id: data.id, status: data.data.status };
  const res = await api.patch(`${BASE_URL}/update/status`, body);
  if (res.error) {
    return { error: parseError(res.error) };
  }

  return { data: 'ok' };
});

export const residentCreate = request.card<Resident, ResidentCreate>(async ({
  api, parseError, data, router,
}) => {
  const id = uuidv4();

  if (
    !data.data.video.length
    && data.data.roles !== 'ROLE_ADMIN'
    && data.data.roles !== 'ROLE_TEAM_CLUB'
    && data.data.roles !== 'ROLE_COMMUNITY_MANAGER'
    
  ) {
    return {
      error: {
        message: '',
        fields: { videoPresentation: 'Поле является обязательным' },
      },
    };
  }

  const body: any = { id, additions: {} };

  const guetLs = localStorage.getItem('new_resident');

  Object.entries(data.data).forEach(([key, val]) => {
    if (createNestedKeys.includes(key)) {
      body.additions[key] = val;
    } else {
      body[key] = val;
    }
  });
  if (data.data.dateOfEntry) {
    const entry = new Date(data.data.dateOfEntry);
    body.dateOfEntry = new Date(entry.getTime() - entry.getTimezoneOffset() * 60 * 1000);
  }
  if (data.data.birthday) {
    const bd = new Date(data.data.birthday);
    body.birthday = new Date(bd.getTime() - bd.getTimezoneOffset() * 60 * 1000);
  }
  if (data.data.roles) body.roles = [data.data.roles] as unknown as ResidentCreate['roles'];
  if (data.data.city) body.additions.city = data.data.city.join(', ');
  if (data.data.interests) body.additions.interests = data.data.interests.join(', ');
  if (data.data.industries) body.additions.industries = data.data.industries.join(', ');
  if (data.data.form_group) body.additions.formGroup = data.data.form_group;
  if (typeof data.data.sex === 'string') body.sex = null;
  if (!data.data.department) body.department = null;
  if (!data.data.recommendationUser || data.data.recommendationUser === '') body.recommendationUser = null;
  if (data.data.chapter) body.chapter = data.data.chapter;

  if (guetLs) {
    body.onGuestMonth = JSON.parse(guetLs);
  } else {
    if (data.data.onGuestMonth && data.data.onGuestMonth.length) body.onGuestMonth = data.data.onGuestMonth[0];
    if (data.data.onGuestMonth && !data.data.onGuestMonth.length) body.onGuestMonth = null;
  }

  if (data.data.avatar) {
    const fileUploadRes = await fileUpload(
      id as string,
      (data.data.avatar as File[])[0],
      'user',
      'user_avatar',
      'userAvatar',
    );

    if (fileUploadRes.error) {
      return {
        error: {
          message: '',
          fields: { avatar: fileUploadRes.error as unknown as string },
        },
      };
    }
    body.avatar = fileUploadRes.data?.id;
  } else {
    body.avatar = null;
  }

  const res = await api.post<{ user: Resident }>(`${BASE_URL}/create`, body);
  if (res.error) {
    return { error: parseError?.(res.error) };
  }

  if (data.data.video.length) {
    const bodyVideo = {
      userId: res.data?.user.id,
      file: data.data.video[0],
    };
  
    await api
      .instance
      .url('/api/admin/user/upload-video-presentation')
      .formData({
        ...bodyVideo,
      })
      .post()
      .json((response) => (response.code === 200 ? { data: response.data } : { error: response.errors }))
      .catch(({ text }) => {
        const error = JSON.parse(text);

        return {
          error: {
            message: '',
            fields: { videoPresentation: 'Поле является обязательным' },
          },
        };
      });
  }

  if (guetLs) {
    localStorage.removeItem('new_resident');
  }

  await router.push({ name: 'resident', params: { id: res.data?.user.id } });

  return { data: res.data?.user };
});

export const residentUpdate = request.card<Resident, ResidentCreate>(async ({ api, parseError, data }) => {
  const body: any = { id: data.id, additions: {} };

  const guetLs = data.id ? localStorage.getItem(String(data.id)) : null;

  Object.entries(data.data).forEach(([key, val]) => {
    if (createNestedKeys.includes(key)) {
      body.additions[key] = val;
    } else {
      body[key] = val;
    }
  });
  if (data.data.dateOfEntry) {
    const entry = new Date(data.data.dateOfEntry);
    body.dateOfEntry = new Date(entry.getTime() - entry.getTimezoneOffset() * 60 * 1000);
  }
  if (data.data.birthday) {
    const bd = new Date(data.data.birthday);
    body.birthday = new Date(bd.getTime() - bd.getTimezoneOffset() * 60 * 1000);
  }
  if (data.data.roles) body.roles = [data.data.roles] as unknown as ResidentCreate['roles'];
  if (data.data.city) body.additions.city = data.data.city.join(', ');
  if (data.data.interests) body.additions.interests = data.data.interests.join(', ');
  if (data.data.industries) body.additions.industries = data.data.industries.join(', ');
  if (typeof data.data.sex === 'string') body.sex = null;
  if (!data.data.department) body.department = null;
  if (!data.data.recommendationUser || data.data.recommendationUser === '') body.recommendationUser = null;
  if (data.data.year_target) body.additions.yearTarget = data.data.year_target;
  if (data.data.provide_resources) body.additions.provideResources = data.data.provide_resources;
  if (data.data.look_resources) body.additions.lookResources = data.data.look_resources;
  if (data.data.number_of_employees) body.additions.numberOfEmployees = data.data.number_of_employees;
  if (data.data.turnover_per_year) body.additions.turnoverPerYear = data.data.turnover_per_year;
  if (data.data.business_start_year) body.additions.businessStartYear = data.data.business_start_year;
  if (data.data.form_group) body.additions.formGroup = data.data.form_group;
  if (data.data.chapter) body.chapter = data.data.chapter;

  if (guetLs) {
    body.onGuestMonth = JSON.parse(guetLs);
  } else {
    if (data.data.onGuestMonth && data.data.onGuestMonth.length) body.onGuestMonth = data.data.onGuestMonth[0];
    if (data.data.onGuestMonth && !data.data.onGuestMonth.length) body.onGuestMonth = null;
  }

  if (data.data.avatar) {
    const fileUploadRes = await fileUpload(
      data.id as string,
      (data.data.avatar as File[])[0],
      'user',
      'user_avatar',
      'userAvatar',
    );

    if (fileUploadRes.error) {
      return {
        error: {
          message: '',
          fields: { avatar: fileUploadRes.error as unknown as string },
        },
      };
    }
    body.avatar = fileUploadRes.data?.id;
  } else {
    body.avatar = null;
  }

  const res = await api.patch<{ user: Resident }>(`${BASE_URL}/update`, body);
  if (res.error) {
    return { error: parseError?.(res.error) };
  }

  if (guetLs) {
    localStorage.removeItem(data.id);
  }

  // Если обновился статус
  if (data.data.status) {
    await residentUpdateStatus(data);
  }

  return { data: res.data?.user };
});

export const residentDelete = request.card(async ({
  api, data, parseError, router,
}) => {
  const res = await api.delete(`${BASE_URL}/delete?id=${data.id}`);
  if (res.error) {
    return { error: parseError(res.error) };
  }

  await router.push({ name: 'residents' });
  return { data: 'ok' };
});

/**
 * Метод получает список всех статусов для резидента
 */
export const residentStatusList = request.card<{rows: UserStatus[]}>(async ({ api }) => {
  const res = await api.get<{ statuses: UserStatus[] }>(`${BASE_URL}/statuses`);
  if (res.error) {
    return {
      data: { rows: [] },
    };
  }

  return {
    data: { rows: res.data?.statuses },
  };
});
